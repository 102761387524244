export const stringService = {
  isValidMail, isPasswordValid, capitalizeFirstLetter
}

/**
 * check if email is valid
 * 
 * @param email 
*/
function isValidMail(email: string): boolean {
  return (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email));
}

/**
 * check if password contain minimum 8 characters with 1 majuscule 1 minuscule 1 number and 1 special character
 * 
 * @param password 
 */
 function isPasswordValid(password: string): boolean {
  if (password === undefined) {
      return false;
  }
  // var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/;
  var decimal = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
  if (password.match(decimal)) {
    return true;
  }
  return false;
}

function capitalizeFirstLetter(name: string): string {
  if (name.indexOf(' ') !== -1) {
    return name[0].toUpperCase() + name.substring(1, name.indexOf(' ') + 1) + name[name.indexOf(' ') + 1].toUpperCase() + name.substring(name.indexOf(' ') + 2);
  }
  if (name.indexOf('-') !== -1) {
    return name[0].toUpperCase() + name.substring(1, name.indexOf('-') + 1) + name[name.indexOf('-') + 1].toUpperCase() + name.substring(name.indexOf('-') + 2);
  }
  return name[0].toUpperCase() + name.substring(1);
}
