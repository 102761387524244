import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../../login/redux/authSlice'
import ordersSlice from '../../orders/redux/ordersSlice';
import documentsSlice from '../../qualityDocuments/redux/documentsSlice';
import estimatesSlice from '../../quotations/redux/estimatesSlice';
import userConnectedSlice from './userConnectedSlice';
import usersSlice from './usersSlice';


const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    userConnected: userConnectedSlice.reducer,
    users: usersSlice.reducer,
    estimates: estimatesSlice.reducer,
    documents: documentsSlice.reducer,
    orders: ordersSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;