import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { RouteApp } from "../../common/model/RouteApp";
import { Order, OrderStatus } from "../model/Order";

interface CardOrderProps {
  order: Order;
}

const CardOrder: FunctionComponent<CardOrderProps> = (props: CardOrderProps) => {

  const navigate = useNavigate();

  return (
    <Paper sx={{
      paddingX: 3,
      paddingTop: window.innerWidth > 1280 ? 3 : 1,
      paddingBottom: window.innerWidth > 1280 ? 8 : 6,
      borderRadius: 4,
      position: 'relative',
      cursor: 'pointer'
    }}
      onClick={() => navigate(RouteApp.ORDERS + RouteApp.ORDER_DETAIL + '/' + props.order.id)}
    >
      <Typography variant="h4" component={'h2'}
        sx={{
          color: 'red',
          fontWeight: 'bold',
          fontSize: window.innerWidth > 1280 ? '2rem' : '1.1rem'
        }}
      >
        N°{props.order.reference}
      </Typography>

      <Typography variant="subtitle1" component={'h3'}
        sx={{
          fontWeight: '800',
          fontSize: window.innerWidth > 1280 ? '1rem' : '0.9rem',
          marginBottom: window.innerWidth > 1280 ? 3 : 1.5,
        }}
      >
        {props.order.total_ttc + " €"}
      </Typography>

      <hr />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="h6" component={'h3'}
          sx={{
            marginLeft: 3,
            fontSize: window.innerWidth > 1280 ? '1rem' : '0.9rem',
            fontWeight: 700
          }}
        >
          Date commande
        </Typography>
        <Typography variant="h6" component={'h3'}
          sx={{
            fontSize: window.innerWidth > 1280 ? '1rem' : '0.9rem',
            fontWeight: 700
          }}
        >
          {props.order.order_date}
        </Typography>
      </Box>

      <hr />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="h6" component={'h3'}
          sx={{
            marginLeft: 3,
            fontSize: window.innerWidth > 1280 ? '1rem' : '0.9rem',
            fontWeight: 700
          }}
        >
          Référence commande
        </Typography>
        <Typography variant="h6" component={'h3'}
          sx={{
            fontSize: window.innerWidth > 1280 ? '1rem' : '0.9rem',
            fontWeight: 700
          }}
        >
          {props.order.internal_number}
        </Typography>
      </Box>

      <hr />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="h6" component={'h3'}
          sx={{
            marginLeft: 3,
            fontSize: window.innerWidth > 1280 ? '1rem' : '0.9rem',
            fontWeight: 700
          }}
        >
          Délais de livraison
        </Typography>
        <Typography variant="h6" component={'h3'}
          sx={{
            fontSize: window.innerWidth > 1280 ? '1rem' : '0.9rem',
            fontWeight: 700
          }}
        >
          {props.order.delivery_date}
        </Typography>
      </Box>

      <hr />

      <Typography
        sx={{
          fontSize: window.innerWidth > 1280 ? '1rem' : '0.9rem',
          fontWeight: 700,
          color: 'white',
          backgroundColor: props.order.status === OrderStatus.CURRENT ? 'orange' : '#50d19e',
          paddingRight: 2,
          paddingLeft: 3,
          paddingY: 0.8,
          borderBottomLeftRadius: 29,
          borderTopLeftRadius: 29,
          position: 'absolute',
          bottom: 14,
          right: 0,
        }}
        component={'span'}
      >
        {props.order.status}
      </Typography>
    </Paper>


  );
}

export default CardOrder;