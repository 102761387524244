import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { FunctionComponent } from "react";
import { getRoleAsText, User } from "../../common/model/User";
import { stringService } from "../../common/service/stringService";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ReactComponent as EditIcon } from '../../images/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../images/delete-icon.svg';
import './ItemUser.scss';
import { useNavigate } from "react-router-dom";
import Popup from "../../common/component/Popup";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { deleteUser } from "../../common/redux/usersActions";
import { RouteApp } from "../../common/model/RouteApp";

interface ItemUserProps {
  user: User
}

const ItemUser: FunctionComponent<ItemUserProps> = (props: ItemUserProps) => {

  const navigate = useNavigate();

  const [isDisplayPopupDeleteUser, setisDisplayPopupDeleteUser] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  function onClickDelete() {
    dispatch(deleteUser(props.user.id!));
    setisDisplayPopupDeleteUser(false);
  }

  return (
    <>
      <Grid container className="item-user-container">
        <Grid item xs={3.6} display={"flex"}>
          <Box margin={'auto'} display='inline-flex' className="item-user-data-name-container">
            <AccountCircleIcon className="icon-person" />
            <Typography className="item-user-data item-user-data-name">
              {stringService.capitalizeFirstLetter(props.user.first_name) + ' ' + props.user.last_name.toUpperCase()}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3.9} display={"flex"}>
          <Typography margin={"auto"} className="item-user-data">{props.user.email}</Typography>
        </Grid>
        <Grid item xs={3.9} display={"flex"}>
          <Typography margin={"auto"} className="item-user-data">{props.user.roles && props.user.roles[0] ? getRoleAsText(props.user.roles[0]) : null}</Typography>
        </Grid>
        <Grid item xs={0.6} display={"flex"} justifyContent={'flex-end'}>
          <Box className="item-user-actions-container">
            <EditIcon className="item-user-edit-icon" onClick={() => navigate(RouteApp.MANAGE_USERS + RouteApp.EDIT_USER + '/' + props.user.id)} />
            <DeleteIcon onClick={() => setisDisplayPopupDeleteUser(true)} />
          </Box>
        </Grid>
      </Grid>
      <Popup
        open={isDisplayPopupDeleteUser}
        title={'Suppression de compte'}
        onClose={() => setisDisplayPopupDeleteUser(false)}
        onCancel={() => setisDisplayPopupDeleteUser(false)}
        titleButtonCancel={"Non"}
        onValidation={onClickDelete}
        titleButtonValidation={"Oui"}
        message={"Etes vous sûr de vouloir supprimer le compte utilisateur de " + stringService.capitalizeFirstLetter(props.user.first_name) + ' ' + props.user.last_name.toUpperCase()}
      />
    </>
  );
}

export default ItemUser;