import React, { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { RootState } from "../../common/redux/store";
import ListItemOrder from "../../orders/component/ListItemOrder";
import { Order } from "../../orders/model/Order";
import './DashboardOrders.scss';
import { getPaginedOrders } from "../../orders/redux/ordersActions";

interface DashboardOrdersProps {

}

const DashboardOrders: FunctionComponent<DashboardOrdersProps> = () => {

  const dispatch = useAppDispatch();

  const ordersSelector = useSelector((rootState: RootState) => rootState.orders)

  useEffect(() => {
    dispatch(getPaginedOrders(1))
  }, []);

  return (
    <div className="dashboard-order-container">
      {
        ordersSelector.data.responsePaginedOrders.orders.map((c: Order, indice: number) => {
          return <ListItemOrder key={indice} order={c} />
        })
      }
    </div>
  );
}

export default DashboardOrders;