export interface Estimate {
  id?: number;
  product: string;
  quantity: number;
  additional_information: string;
  created_at?: Date;
  clarify?: Clarify[];
  reference?: number;
  estimate_number?: number;
  status?: EstimateStatus;
  files: File[];
  url_estimate?: string;
  url_files?: string[];
  commercial?: Commercial | null;
}

export interface EstimateStatus {
  id: number,
  name: string
}

export interface Clarify {
  message: string;
  is_request_clarification: boolean;
}

export interface Commercial {
  id: number;
  last_name: string;
  first_name: string;
}

export interface ResponsePaginedEstimates {
  estimates: Estimate[];
  page: number, 
  page_size: number, 
  max_page: number, 
  total_items: number,
}

export const emptyResponsePaginedEstimates: ResponsePaginedEstimates = {
  estimates: [],
  page: 0, 
  page_size: 0, 
  max_page: 0, 
  total_items: 0,
}

export interface EstimateUpdateClarify {
  id: number;
  clarify: string;
}

export interface EstimateUpdateFiles {
  id: number;
  files: File[];
}

export interface FilterEstimate {
  type: 1 | 4, // 1 = demande, 4 = devis
  property: "createdAt" | "estimateNumber" | "status" | 'empty',
  value?: string | number | null,
  start_date: string | null,
  end_date: string | null,
  numPage: number,
  nbResultPerPage: number
}