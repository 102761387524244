import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseToken } from "../../login/model/ResponseToken";
import { authActions } from "../../login/redux/authAction";
import { ResponseMessage } from "../model/ResponseMessage";
import { Company, DataChangePassword, FilterUser, ResponsePaginedUsers, User, UserToEdit } from "../model/User";
import { api, RouteAPI } from "../service/apiService";
import { jwtService } from "../service/jwtService";
import { storageService } from "../service/storageService";
import { setUserConnected } from "./userConnectedSlice";

const isResoFullHD: boolean = window.innerWidth > 1280;

export const register = createAsyncThunk<ResponseMessage, User>(
    "users/register",
    async (userToRegister, thunkAPI) => {
        try {
            const response = await api(RouteAPI.REGISTER, 'POST', userToRegister);
            const responseToken: ResponseToken = response.data as ResponseToken;
            storageService.setToken(responseToken.token);
            storageService.setRefreshToken(responseToken.refresh_token);
            const userFromToken: User = jwtService.getDecodedToken() as User;
            thunkAPI.dispatch(setUserConnected(userFromToken));
            thunkAPI.dispatch(authActions.setLogin(response.data as ResponseToken));
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const resetPassword = createAsyncThunk<ResponseMessage, string>(
    "users/resetPassword",
    async (email, thunkAPI) => {
        try {
            const bodyValue = {email: email};
            const response = await api(RouteAPI.RESET_PASSWORD, 'POST', bodyValue);
            return response.data as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const changePassword = createAsyncThunk<ResponseMessage, DataChangePassword>(
    "users/changePassword",
    async (dataChangePassword, thunkAPI) => {
        try {
            const {plainPassword, token} = dataChangePassword;
            const response = await api(RouteAPI.CHANGE_PASSWORD + token, 'POST', plainPassword);
            return response.data  as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const changePasswordWithIdUser = createAsyncThunk<ResponseMessage, DataChangePassword>(
    "users/changePasswordWithIdUser",
    async (dataChangePassword, thunkAPI) => {
        try {
            const {plainPassword, idUser} = dataChangePassword;
            const response = await api(RouteAPI.CHANGE_PASSWORD_WITH_ID + idUser, 'POST', plainPassword);
            return response.data as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const getPaginedUsers = createAsyncThunk<ResponsePaginedUsers, number>(
    "users/getPaginedUsers",
    async (numPage, thunkAPI) => {
        try {
            const nbResultPerPage = isResoFullHD ? 5 : 4;
            const response = await api(RouteAPI.USER + "/" + numPage + "/" + nbResultPerPage, 'GET');
            return response.data as ResponsePaginedUsers;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const getFilteredPaginedUsers = createAsyncThunk<ResponsePaginedUsers, FilterUser>(
    "users/getFilteredPaginedUsers",
    async (filterUser, thunkAPI) => {
        try {
            const nbResultPerPage = isResoFullHD ? 5 : 4;
            const bodyRequest = {
                property: filterUser.property,
                value: filterUser.value
            }
            const response = await api(RouteAPI.USER_FILTERED + filterUser.numPage + "/" + nbResultPerPage, 'POST', bodyRequest);
            return response.data as ResponsePaginedUsers;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)
 
export const getFilteredCompanies = createAsyncThunk<Company[], string>(
    "users/getFilteredCompanies",
    async (searchValueCompany, thunkAPI) => {
        try {
            const response = await api(RouteAPI.COMPANY + '/' + searchValueCompany, 'GET');
            return response.data as Company[];
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)
/**
 * 
 * @param user 
 * @returns user without id
 */
const generateUserForWS = (user: UserToEdit) => {
    const userForWs = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        username: user.username,
        company: user.company,
        phone: user.phone,
        roles: user.roles
    }
    return userForWs;
}

export const addUser = createAsyncThunk<ResponseMessage, UserToEdit>(
    "users/add",
    async (user, thunkAPI) => {
        try {
            const response = await api(RouteAPI.USER, 'POST', user);
            return response.data as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const getUser = createAsyncThunk<User, number>(
    "users/getOne",
    async (idUser, thunkAPI) => {
        try {
            const response = await api(RouteAPI.USER + '/' + idUser, 'GET');
            return response.data as User;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const updateUser = createAsyncThunk<ResponseMessage, UserToEdit>(
    "users/update",
    async (user, thunkAPI) => {
        try {
            const response = await api(RouteAPI.USER + '/' + user.id, 'PUT', generateUserForWS(user));
            return response.data as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const deleteUser = createAsyncThunk<ResponseMessage, number>(
    "users/delete",
    async (idUser, thunkAPI) => {
        try {
            const response = await api(RouteAPI.USER + '/' + idUser, 'DELETE');
            return response.data as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)