import authSlice, { initialAuthData } from "./authSlice";
import { createAsyncThunk, ThunkAction } from '@reduxjs/toolkit';
import { AnyAction } from '@reduxjs/toolkit';
import { ResponseToken } from "../model/ResponseToken";
import { SignInData } from "../model/SignInData";
import { RootState } from "../../common/redux/store";
import { storageService } from "../../common/service/storageService";
import { api, RouteAPI } from "../../common/service/apiService";
import { AxiosError, AxiosResponse } from "axios";
import { jwtService } from "../../common/service/jwtService";
import { User } from "../../common/model/User";
import { setUserConnected } from "../../common/redux/userConnectedSlice";
import { ResponseMessage } from "../../common/model/ResponseMessage";

export const authActions = authSlice.actions;

export const login = (signInData: SignInData): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch, getState) => {
    dispatch(authActions.setLoginStart());
    api(RouteAPI.LOGIN, 'POST', { username: signInData.username, password: signInData.password })
      .then((response: AxiosResponse<ResponseToken>) => {
        const responseToken: ResponseToken = response.data;
        storageService.setToken(responseToken.token);
        storageService.setRefreshToken(responseToken.refresh_token);
        const userFromToken: User = jwtService.getDecodedToken() as User;
        dispatch(setUserConnected(userFromToken));
        dispatch(authActions.setLogin(responseToken));
      })
      .catch((error: AxiosError<ResponseMessage>) => {
        dispatch(authActions.setLoginError(error));
      })
  }
}

export const logout = (isFromEditMyAccount?: boolean): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch, getState) => {
    dispatch(invalidateToken(isFromEditMyAccount));
  }
}

export async function refreshToken(): Promise<AxiosResponse<any, any>> {
  return await api(RouteAPI.REFRESH_TOKEN, 'POST', { refresh_token: storageService.getRefreshToken() });
}

export const invalidateToken = createAsyncThunk<any, boolean | undefined>(
  "auth/invalidateToken",
  async (isFromEditMyAccount, thunkAPI) => {
    try {
      // c est une deconnexion suite a la modification de l identifiant de connexion
      // le token stocker dans le storage ne correspond plus
      if (!isFromEditMyAccount) {
        await api(RouteAPI.INVALIDATE_TOKEN, 'POST');
      }
      storageService.removeToken();
      storageService.removeRefreshToken();
      thunkAPI.dispatch(authActions.setLogin(initialAuthData));
      thunkAPI.dispatch(setUserConnected(undefined));
    } catch (error) {
      storageService.removeToken();
      storageService.removeRefreshToken();
      thunkAPI.dispatch(authActions.setLogin(initialAuthData));
      thunkAPI.dispatch(setUserConnected(undefined));
      return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
)