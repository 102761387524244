import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../model/User'

export interface UserConnectedState {
  user: User | undefined;
}

const initialState: UserConnectedState = {
  user: undefined
}

export const userConnectedSlice = createSlice({
  name: 'userConnected',
  initialState,
  reducers: {
    setUserConnected: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserConnected } = userConnectedSlice.actions

export default userConnectedSlice