import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomSnackbar from "../../common/component/CustomSnackbar";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteApp } from "../../common/model/RouteApp";
import { TypeDoc } from "../../common/model/TypeDoc";
import { Role, User } from "../../common/model/User";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { RootState } from "../../common/redux/store";
import { ReactComponent as PlusIcon } from '../../images/Icon-plus-circle-noir.svg';
import imageback from '../../images/img-fond-fonce.svg';
import { DocumentToDisplay } from "../model/Document";
import { getAllQualityDocument } from "../redux/documentsActions";
import { documentsSetResponseMessage, setDocument } from "../redux/documentsSlice";
import ItemDoc from "./ItemDoc";
import './QualityDocumentsPage.scss';

interface QualityDocumentsPageProps {

}

const QualityDocumentsPage: FunctionComponent<QualityDocumentsPageProps> = () => {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const userConnected: User | undefined = useSelector((rootState: RootState) => rootState.userConnected.user);

  const documentsSelector = useSelector((rootState: RootState) => rootState.documents);

  const [snackbarMessage, setsnackbarMessage] = useState<ResponseMessage | undefined>(undefined);
  const [openSnackbar, setopenSnackbar] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAllQualityDocument());
  }, []);

  useEffect(() => {
    if (documentsSelector.data.responseMessage) {
      setsnackbarMessage(documentsSelector.data.responseMessage);
      setopenSnackbar(true)
      dispatch(documentsSetResponseMessage(undefined));
      if (documentsSelector.requestType === 'DELETE') {
        dispatch(getAllQualityDocument());
      }
    }
  }, [documentsSelector]);

  function handleCloseSnackbar() {
    setopenSnackbar(false);
  }

  return (
    <Box
      className="page-container"

      sx={{
        backgroundImage: `url(${imageback})`,
      }}
    >
      <Container className="container">
        <Box className="title-container" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography className='title-page' variant='h4' component="h1">
            Certifications / Qualifications
          </Typography>

          {
            userConnected && userConnected.roles && (userConnected.roles[0] === Role.Admin || userConnected.roles[0] === Role.Commercial) &&
            <Button
              variant="text"
              className="btn-nouvelle-demande"
              sx={{
                backgroundColor: 'white',
                marginY: 'auto',
                textTransform: 'none'
              }}
              onClick={() => { navigate(RouteApp.QUALITY_DOCUMENTS + RouteApp.EDIT); dispatch(setDocument(undefined)); }}
            >
              <PlusIcon className="icon-plus" />Ajouter document
            </Button>
          }

        </Box>

        {
          documentsSelector.data.documents.length === 0 && documentsSelector.isSuccess &&
          <h1 className="title-no-doc-available">Aucun document disponible</h1>
        }
        <Box className="list-doc-container">
          <Grid className="list-item-doc-container" container spacing={6} marginTop='auto'>
            {
              documentsSelector.data.documents.map((doc: DocumentToDisplay, indice: number) => {
                return (
                  <Grid key={indice} item lg={3} md={6} sm={6} xs={12}>
                    <ItemDoc doc={doc} typeDoc={TypeDoc.QUALITY} />
                  </Grid>
                )
              })
            }
          </Grid>
        </Box>
      </Container>
      <CustomSnackbar
        open={snackbarMessage !== undefined && openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        message={snackbarMessage?.message ? snackbarMessage.message : ''}
        severity={snackbarMessage?.status === 200 ? "success" : "error"}
        position={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Box>
  );
}

export default QualityDocumentsPage;