import { Box, Container, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { User } from "../../common/model/User";
import { RootState } from "../../common/redux/store";
import imageback from '../../images/img-fond-fonce.svg';
import MyAccountEditable from "./MyAccountEditable";
import MyAccountView from "./MyAccountView";
import './MyAccountPage.scss';
import { ResponseMessage } from "../../common/model/ResponseMessage";
import CustomSnackbar from "../../common/component/CustomSnackbar";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { usersSetError, usersSetResponseMessage } from "../../common/redux/usersSlice";
import { stringService } from "../../common/service/stringService";

const isResoFullHD: boolean = window.innerWidth > 1280;

interface MyAccountPageProps {

}

const MyAccountPage: FunctionComponent<MyAccountPageProps> = () => {

  const [isEditMode, setisEditMode] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const userConnected: User | undefined = useSelector((state: RootState) => state.userConnected.user);

  const responseMessageEditUser = useSelector((state: RootState) => state.users.data.responseMessage);

  const errorWS = useSelector((state: RootState) => state.users.error)

  useEffect(() => {
    if (responseMessageEditUser) {
      setsnackbarMessage(responseMessageEditUser)
      setopenSnackbar(true);
      dispatch(usersSetResponseMessage(undefined));
      setisEditMode(false);
    }
  }, [responseMessageEditUser]);

  useEffect(() => {
    if (errorWS) {
      setsnackbarMessage(errorWS.response?.data);
      setopenSnackbar(true);
      dispatch(usersSetError(undefined));
    }
  }, [errorWS]);

  const [snackbarMessage, setsnackbarMessage] = useState<ResponseMessage | undefined>(undefined);
  const [openSnackbar, setopenSnackbar] = useState<boolean>(false);

  function handleCloseSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
    setopenSnackbar(false);
  }

  return (
    <Box
      className="page-container my-account-page-container"
      sx={{
        backgroundImage: `url(${imageback})`
      }}
    >
      <Container className="container">
        <Typography className='title-page' variant='h4' component="h1">
          Mon Compte
        </Typography>
        <Paper sx={{
          margin: isResoFullHD ? (isEditMode ? '3% auto 16% auto' : '8% auto 16% auto') : '0% auto'
        }}>
          <h2 className="my-account-page-name">{stringService.capitalizeFirstLetter(userConnected?.first_name ? userConnected?.first_name : '') + " " + userConnected?.last_name.toUpperCase()}</h2>
          {
            isEditMode ?
              <MyAccountEditable setisEditMode={setisEditMode} />
              :
              <MyAccountView setisEditMode={setisEditMode} />
          }
        </Paper>
      </Container>
      <CustomSnackbar
        open={snackbarMessage !== undefined && openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        message={snackbarMessage ? snackbarMessage.message : ''}
        severity={snackbarMessage?.status === 200 ? "success" : "error"}
        position={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={responseMessageEditUser?.status === 200 ? 4000 : 6000}
      />

    </Box>
  );
}

export default MyAccountPage;