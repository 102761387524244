import { Autocomplete, AutocompleteInputChangeReason, Box, Button, Chip, Container, Grid, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import imageback from '../../images/img-fond-fonce.svg';
import DragDropFile from "../../common/component/DragDropFile";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from "react-router-dom";
import CustomSnackbar from "../../common/component/CustomSnackbar";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { useSelector } from "react-redux";
import { RootState } from "../../common/redux/store";
import { CompanyForDocument, DocumentForEdition } from "../../qualityDocuments/model/Document";
import { createCustomerDocument, getDocumentById, updateDocument } from "../../qualityDocuments/redux/documentsActions";
import { setCompanies } from "../../common/redux/usersSlice";
import { getFilteredCompanies } from "../../common/redux/usersActions";
import { setDocument } from "../../qualityDocuments/redux/documentsSlice";
import { RouteApp } from "../../common/model/RouteApp";
import './AddCustomerDocumentsPage.scss';

const isResoFullHD: boolean = window.innerWidth > 1280;

interface AddCustomerDocumentsPageProps {

}

const AddCustomerDocumentsPage: FunctionComponent<AddCustomerDocumentsPageProps> = () => {

  const { idDoc } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [company, setcompany] = useState<CompanyForDocument | null>(null);
  const [isCompanyModified, setisCompanyModified] = useState<boolean>(false);
  const [searchValueCompany, setsearchValueCompany] = useState<string>('');
  const [docName, setdocName] = useState<string>('');
  const [isDocNameModified, setisDocNameModified] = useState<boolean>(false);
  const [imgFile, setimgFile] = useState<File>();
  const [isImgFileModified, setisImgFileModified] = useState<boolean>(false);
  //utiliser uniquement pour la prévisualisation
  const [fileDataURL, setFileDataURL] = useState<string | ArrayBuffer | null | undefined>(null);
  const [pdfFile, setpdfFile] = useState<File>();
  const [pdfFileName, setpdfFileName] = useState<string>();
  const [isPdfFileModified, setisPdfFileModified] = useState<boolean>(false);

  const [snackbarMessage, setsnackbarMessage] = useState<ResponseMessage | undefined>(undefined);
  const [openSnackbar, setopenSnackbar] = useState<boolean>(false);

  const documentsSelector = useSelector((rootState: RootState) => rootState.documents);

  const usersSelector = useSelector((state: RootState) => state.users);

  useEffect(() => {
    let fileReader = new FileReader();
    let isCancel = false;
    if (imgFile) {
      fileReader.onload = (e) => {
        const result = e.target?.result;
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(imgFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }

  }, [imgFile]);

  function onChangeSearchValueCompany(searchValueCompany: string, reason: AutocompleteInputChangeReason) {
    if (reason === "clear") {
      dispatch(setCompanies([]));
    }
    if (searchValueCompany.length > 2 && reason !== "reset") {
      dispatch(getFilteredCompanies(searchValueCompany))
    }
    setsearchValueCompany(searchValueCompany);
  }

  function addFileList(fileList: FileList) {
    if ((fileList[0].size / 1024 / 1024) > 8) {
      setsnackbarMessage({ status: 500, message: "la taille du fichier ne doit pas dépasser 8Mo" })
      setopenSnackbar(true)
    }
    else {
      setpdfFile(fileList[0]);
      setisPdfFileModified(true);
    }
  }

  const handleChangeImgInputFile = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setimgFile(e.target.files[0]);
      setisImgFileModified(true);
    }
  };

  function generateFormData(): FormData {
    const formData = new FormData();
    if (isCompanyModified) {
      formData.append("company", company!.id.toString());
    }
    formData.append("name", docName);
    if (isImgFileModified) {
      formData.append("picture", imgFile !== undefined ? imgFile : '');
    }
    if (isPdfFileModified) {
      formData.append("file", pdfFile!);
    }
    return formData;
  }

  function addQualityDocument() {
    let errorMessage = idDoc ? checkInformationUpdate() : checkInformationCreate();
    if (errorMessage) {
      setsnackbarMessage({ status: 500, message: errorMessage })
      setopenSnackbar(true)
    }
    else {
      if (idDoc) {
        dispatch(updateDocument({
          id: Number.parseInt(idDoc),
          formData: generateFormData(),
        }));
      }
      else {
        const doc: DocumentForEdition = {
          name: docName,
          picture: imgFile,
          file: pdfFile!,
          company: company!
        }
        dispatch(createCustomerDocument(doc));
      }
    }
  }

  function checkInformationCreate(): string | null {
    if (docName.length === 0) {
      return 'Le nom du document est obligatoire';
    }
    if (pdfFile === undefined) {
      return "L\'upload de fichier est obligatoire";
    }
    return null;
  }

  function checkInformationUpdate(): string | null {
    if (isDocNameModified && docName.length === 0) {
      return 'Le nom du document est obligatoire';
    }
    if (isPdfFileModified && pdfFile === undefined) {
      return "L\'upload de fichier est obligatoire";
    }
    return null;
  }

  function handleCloseSnackbar() {
    setopenSnackbar(false)
  }

  useEffect(() => {
    if (documentsSelector.error) {
      setsnackbarMessage(documentsSelector.error.response?.data)
      setopenSnackbar(true)
    }
    if (documentsSelector.isSuccess && documentsSelector.data.responseMessage) {
      navigate(RouteApp.CUSTOMER_DOCUMENTS);
    }
    if (documentsSelector.requestType === 'GET' && documentsSelector.data.document) {
      const doc = documentsSelector.data.document;
      setcompany(doc.company!);
      setdocName(doc.name);
      setFileDataURL(doc.picture);
      setpdfFileName(doc.display_name);
    }
  }, [documentsSelector]);

  useEffect(() => {
    if (idDoc) {
      dispatch(getDocumentById(Number.parseInt(idDoc)));
    }
  }, [idDoc]);

  return (
    <Box
      className="page-container"
      sx={{
        backgroundImage: `url(${imageback})`
      }}
    >
      <Container className="container">

        <Button
          onClick={() => {
            navigate(RouteApp.CUSTOMER_DOCUMENTS);
            dispatch(setDocument(undefined));
          }}
          variant="contained" className="back-button"
          startIcon={<KeyboardBackspaceIcon />}
        >
          Retour
        </Button>

        <Paper sx={{ borderRadius: '0', paddingBottom: isResoFullHD ? '2em' : '0.8em' }}>
          <Typography variant='h4' component="h1" sx={{ padding: isResoFullHD ? '15px 1.5em' : '8px 0.8em', borderRadius: '0', lineHeight: '1', fontWeight: '600' }}>
            Ajouter un document Client
          </Typography>
          <hr />

          <Box display={"flex"} sx={{ padding: isResoFullHD ? '5em 2em' : '1em 1em', flexWrap: 'wrap' }}>
            <Grid container spacing={3}>
              {/* PARTIE GAUCHE */}
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >

                  <Autocomplete
                    value={company}
                    onChange={(event: any, newValue: CompanyForDocument | null) => {
                      setcompany(newValue);
                      setisCompanyModified(true);
                    }}
                    inputValue={searchValueCompany}
                    onInputChange={(event, newInputValue, reason) => {
                      onChangeSearchValueCompany(newInputValue, reason);
                    }}
                    id="company"
                    sx={{ marginBottom: '1em' }}
                    options={usersSelector.data.companies}
                    getOptionLabel={(company) => company.name || ""}
                    renderInput={(params) => <TextField required {...params} label="Société" variant="outlined" />}
                  />

                  <TextField
                    id="doc_name" label="Nom du document" variant="outlined" required
                    sx={{ marginBottom: isResoFullHD ? '1em' : '0.5em' }}
                    value={docName} onChange={(e) => { setdocName(e.target.value); setisDocNameModified(true); }}
                    disabled={company === null}
                  />

                  <Button variant="contained" component="label" disabled={company === null} sx={{ marginTop: '3em', fontSize: '1.2em', textTransform: 'inherit', borderRadius: '10px' }}>
                    Ajouter une image
                    <input hidden accept="image/*" type="file" onChange={(e) => handleChangeImgInputFile(e)} />
                  </Button>

                  {
                    fileDataURL &&
                    (
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', marginTop: '1em' }}>
                        <img src={fileDataURL.toString()} alt="preview" style={{
                          width: '300px',
                          height: '150px'
                        }} />
                        <IconButton aria-label="fermer" onClick={() => { setimgFile(undefined); setFileDataURL(undefined); setisImgFileModified(true); }}
                          sx={{
                            backgroundColor: 'red', color: 'white',
                            '&:hover': {
                              backgroundColor: 'red', color: 'white', opacity: '0.6'
                            }
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )
                  }
                </Box>
              </Grid>

              {/* PARTIE DROITE */}
              <Grid item xs={12} md={7}>
                <Box
                  className="addcustomer-right"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',

                  }}
                >

                  {
                    (pdfFile || pdfFileName) &&
                    <Box sx={{
                      backgroundColor: 'white',
                      padding: '10px',
                      borderRadius: '20px',
                      marginY: '10px'
                    }}>
                      <Stack direction="row" spacing={1}>
                        <Chip label={pdfFile ? pdfFile.name : pdfFileName} variant="outlined" onDelete={() => {
                          setpdfFile(undefined);
                          setpdfFileName(undefined);
                          setisPdfFileModified(true);
                        }} />
                      </Stack>
                    </Box>
                  }

                  <DragDropFile message="Déposez votre document ici ou " accept=".pdf" addFileList={addFileList} disable={company === null} />
                  <Button
                    variant="contained" component="label"
                    sx={{ display: 'flex', fontSize: '1.4em', borderRadius: '10px', marginTop: '3em' }}
                    onClick={() => addQualityDocument()} disabled={company === null}
                  >
                    Valider
                  </Button>

                </Box>
              </Grid>

            </Grid>

          </Box>


        </Paper>
        <CustomSnackbar
          open={snackbarMessage !== undefined && openSnackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={snackbarMessage?.message ? snackbarMessage.message : ''}
          severity={snackbarMessage?.status === 200 ? "success" : "error"}
          position={{ vertical: 'bottom', horizontal: 'center' }}
        />

      </Container>
    </Box >
  );
}

export default AddCustomerDocumentsPage;