import { AxiosResponse, Method } from 'axios';
import axiosInstance from '../apiCall/globalInterceptorSetup';

/**
 * Route API
*/
export const RouteAPI = {
  LOGIN: '/login',
  REFRESH_TOKEN: '/token/refresh',
  REGISTER: '/register',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/reset-password/reset/',
  CHANGE_PASSWORD_WITH_ID: '/user/change-password/',
  USER: '/user',
  USER_FILTERED: '/user/search/',
  COMPANY: '/company',
  INVALIDATE_TOKEN: '/token/invalidate',
  ESTIMATE: '/estimate',
  ESTIMATE_FILTERED: '/estimate/filter/',
  ESTIMATE_STATUS: '/estimate/status',
  EDIT: '/edit',
  ASSIGN: '/assign',
  PRICE: '/price',
  DOCUMENT: '/document',
  QUALITY: '/quality',
  CUSTOMER: '/customer',
  ORDER: '/order',
  ORDER_FILTERED: '/order/filter/'
}

/**
 * Consume API
 *
 * @param url is the server route URL that will be used for the request
 * @param method is the request method to be used when making the request
 * @param data is the data to be sent as the request body. ex {id: '1234'}:
 * @param params are the URL parameters to be sent with the request. ex {firstname: 'Fred'}:
 *
 * @returns Promise<R>
 */
 export function api<T = any, R = AxiosResponse<T>>(
  url: string,
  method: Method,
  data: any = {},
  params: any = {}
): Promise<R> {
  return axiosInstance.request({
      url: url,
      method: method,
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 30000,
      params: params,
      data: data,
  });
}