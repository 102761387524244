/**
 * Key storage
 */
export const localStorageType = {
  TOKEN: 'aeroflex.espace-client.token',
  REFRESH_TOKEN: 'aeroflex.espace-client.refresh_token',
  ERROR_MESSAGE_REFRESH_TOKEN: 'aeroflex.espace-client.error_message_refresh_token',
};

/**
 * Exported function
 */
export const storageService = {
  getToken,
  setToken,
  removeToken,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
  getErrorMessageRefreshToken,
  setErrorMessageRefreshToken,
  removeErrorMessageRefreshToken,
};

export const ERROR_MESSAGE_REFRESH_TOKEN = 'Votre session a expiré.';

/**
 * Return access token
 *
 *  @returns string | null
 */
function getToken(): string | null {
  return localStorage.getItem(localStorageType.TOKEN);
}

/**
 * Return access refreshtoken
 *
 *  @returns string | null
 */
 function getRefreshToken(): string | null {
  return localStorage.getItem(localStorageType.REFRESH_TOKEN);
}

/**
 * Add access token to storage
 *
 * @param token string
 */
function setToken(token: string) {
  localStorage.setItem(localStorageType.TOKEN, token);
}

/**
 * Add refresh token to storage
 *
 * @param refreshToken string
 */
 function setRefreshToken(refreshToken: string) {
  localStorage.setItem(localStorageType.REFRESH_TOKEN, refreshToken);
}

/**
 * Remove access token to storage
 */
function removeToken() {
  localStorage.removeItem(localStorageType.TOKEN);
}

/**
 * Remove refresh token to storage
 */
 function removeRefreshToken() {
  localStorage.removeItem(localStorageType.REFRESH_TOKEN);
}

/**
 * Return error message refreshtoken
 *
 *  @returns string | null
 */
function getErrorMessageRefreshToken(): string | null {
  return localStorage.getItem(localStorageType.ERROR_MESSAGE_REFRESH_TOKEN);
}

/**
 * Add error message refresh token to storage
 *
 */
function setErrorMessageRefreshToken() {
  localStorage.setItem(localStorageType.ERROR_MESSAGE_REFRESH_TOKEN, ERROR_MESSAGE_REFRESH_TOKEN);
}

/**
 * Remove error message refresh token to storage
 */
function removeErrorMessageRefreshToken() {
  localStorage.removeItem(localStorageType.ERROR_MESSAGE_REFRESH_TOKEN);
}