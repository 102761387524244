export enum OrderStatus {
  CURRENT = 'En cours',
  PAST = 'Passées'
}

export interface Order {
  id?: number;
  order_date: string;
  internal_number: string;
  reference: string;
  delivery_date: string;
  contact: any | null;
  status: string;
  total_ttc: string;
}

export interface ResponsePaginedOrders {
  orders: Order[];
  page: number;
  page_size: number;
  max_page: number;
  total_items: number;
}

export const emptyResponsePaginedOrders: ResponsePaginedOrders = {
  orders: [],
  page: 0, 
  page_size: 0, 
  max_page: 0, 
  total_items: 0,
}

export interface FilterOrder {
  nbResultPerPage: number,
  type: 1 | 6, // 1 = encours, 6 = passées
  property: 'orderDate' | 'deliveryDate' | 'company' | 'status' | 'applicant' | 'empty',
  value?: string | number | null,
  start_date: string | null,
  end_date: string | null,
  numPage: number
}