import { Button, Link, Paper, Stack, Typography } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { TypeDoc } from "../../common/model/TypeDoc";
import './ItemDoc.scss';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { RootState } from "../../common/redux/store";
import { useSelector } from "react-redux";
import { Role, User } from "../../common/model/User";
import { DocumentToDisplay } from "../model/Document";
import DefaultImgDoc from '../../images/default-img-document.svg';
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { deleteDocument } from "../redux/documentsActions";
import Popup from "../../common/component/Popup";
import { useNavigate } from "react-router-dom";
import { RouteApp } from "../../common/model/RouteApp";

export interface ItemDocProps {
  doc: DocumentToDisplay;
  typeDoc: TypeDoc;
}

const ItemDoc: FunctionComponent<ItemDocProps> = (props: ItemDocProps) => {

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const userConnected: User | undefined = useSelector((state: RootState) => state.userConnected.user);

  const [isDisplayPopupDeleteDocument, setisDisplayPopupDeleteDocument] = useState<boolean>(false);

  function getTextLink(): string {
    if (props.typeDoc === TypeDoc.CUSTOMER)
      return 'Voir le document';
    return 'Voir la certification';
  }

  function isUserAvailableToEditDocument(): boolean {
    if (userConnected?.roles && ((userConnected?.roles[0] === Role.Admin) || (userConnected?.roles[0] === Role.Commercial)))
      return true;
    return false;
  }

  function onClickDelete() {
    dispatch(deleteDocument(props.doc.id!));
    setisDisplayPopupDeleteDocument(false);
  }

  return (
    <>
      <Paper className='item-doc-container' sx={{ paddingLeft: '0' }}>
        <Typography 
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '15rem',
            whiteSpace: 'nowrap'
          }} 
          className='item-doc-title' variant='h5' component="h2">
          {props.doc.name}
        </Typography>
        <img className='item-doc-icon' src={props.doc.picture ? props.doc.picture : DefaultImgDoc} alt={"logo"} />
        <Link href={props.doc.file} className="bouton-certification-pdf" underline="none" target="_blank" rel="noreferrer">
          <Typography className="link">{getTextLink()}</Typography>
        </Link>
      </Paper>

      {
        isUserAvailableToEditDocument() &&
        <Stack direction="row" spacing={1} justifyContent="center" marginTop={'20px'}>
          <Button
            variant="contained"
            startIcon={<DeleteForeverIcon />}
            className="item-doc-delete-btn"
            onClick={() => setisDisplayPopupDeleteDocument(true)}
            sx={{ borderRadius: '10px' }}
          >
            Supprimer
          </Button>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            className="item-doc-modify-btn"
            sx={{ borderRadius: '10px' }}
            onClick={() => {
              navigate(props.typeDoc === TypeDoc.QUALITY ?
                RouteApp.QUALITY_DOCUMENTS + RouteApp.EDIT + '/' + props.doc.id
                :
                RouteApp.CUSTOMER_DOCUMENTS + RouteApp.EDIT + '/' + props.doc.id
              )
            }}
          >
            Modifier
          </Button>
        </Stack>
      }
      <Popup
        open={isDisplayPopupDeleteDocument}
        title={'Suppression de document'}
        onClose={() => setisDisplayPopupDeleteDocument(false)}
        onCancel={() => setisDisplayPopupDeleteDocument(false)}
        titleButtonCancel={"Non"}
        onValidation={onClickDelete}
        titleButtonValidation={"Oui"}
        message={"Etes vous sûr de vouloir supprimer le document \"" + props.doc.name + "\""}
      />
    </>
  );
}

export default ItemDoc;