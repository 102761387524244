export const RouteApp = {
  DASHBOARD: "/",

  LOGIN: "/connexion",

  ORDERS: "/commandes",
  ORDER_DETAIL: "/detail-commande",
  DATASHEETS: "/fiches-techniques",


  ESTIMATES: "/devis",
  NEW_REQUEST: "/nouvelle-demande",
  PRICE_REQUEST_DETAIL: "/detail-demande-prix",

  QUALITY_DOCUMENTS: "/documents-qualite",

  CUSTOMER_DOCUMENTS: "/documents-clients",

  EDIT: "/editer",

  MY_ACCOUNT: "/mon-compte",

  CHANGE_PASSWORD: "/modifier-mot-de-passe",

  FORGOT_PASSWORD: "/mot-de-passe-oublie",

  REGISTRATION: "/inscription",

  MANAGE_USERS: "/gestion-utilisateurs",
  EDIT_USER: "/edition",
}