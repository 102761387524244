import React from "react";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RouteApp } from "../model/RouteApp";
import { Role, User } from "../model/User";
import { RootState } from "../redux/store";

interface PrivateRouteProps {
  roles: Role[];
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  roles,
  children,
}) => {

  const userConnected: User | undefined = useSelector((state: RootState) => state.userConnected.user);

  function getPermissionUserFromArray(roles: Role[]): boolean {
    let permission = false;

    roles.map((r: Role) => {
      if (userConnected?.roles && userConnected.roles[0] === r) {
        permission = true;
      }
    })

    return permission;
  }

  return getPermissionUserFromArray(roles) ? <>{children}</> : <Navigate to={RouteApp.LOGIN} />;
};

export default PrivateRoute;
