export interface User {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  company?: String | Company | null;
  phone: string;
  roles?: Role[];
  password?: Password;
}

export interface UserToEdit {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  company: number | null;
  phone: string;
  roles: Role[];
}

export interface ErrorsFormUser {
  first_name: boolean;
  last_name: boolean;
  email: boolean;
  username: boolean;
  company: boolean;
  phone: boolean;
}

export interface Company {
  id: number;
  name: string;
  from_sage: boolean;
}

export interface Password {
  first: string;
  second: string;
}

export interface PlainPassword {
  plainPassword: Password;
}

export interface DataChangePassword {
  plainPassword: PlainPassword;
  token?: string;
  idUser?: number;
}

export enum Role {
  Admin = 'ROLE_ADMIN',
  Commercial = 'ROLE_COMMERCIAL',
  SuperCustomer = 'ROLE_SUPER_CUSTOMER',
  Customer = 'ROLE_CUSTOMER',
  Prospect = 'ROLE_PROSPECT',
}

export interface ResponsePaginedUsers {
  users: User[];
  page: number, 
  page_size: number, 
  max_page: number, 
  total_items: number,
}

export const emptyResponsePaginedUsers: ResponsePaginedUsers = {
  users: [],
  page: 0, 
  page_size: 0, 
  max_page: 0, 
  total_items: 0,
}

export function getRoleAsText(role: Role | string): string {
  if (role === Role.Admin) {
    return 'Administrateur';
  }
  else if (role === Role.Commercial) {
    return 'Commercial';
  }
  else if (role === Role.SuperCustomer) {
    return 'Admin société';
  }
  else if (role === Role.Customer) {
    return 'Client';
  }
  else if (role === Role.Prospect) {
    return 'Prospect';
  }
  return '';
}

export const emptyUser: User = {  
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  company: null,
  phone: '',
  roles: undefined,
  password: {
    first: '',
    second: ''
  },
}

export interface FilterUser {
  property: 'lastName' | 'firstName' | 'email' | 'company' | 'roles',
  value?: string,
  numPage: number
}