import { Box, Button, Container, Paper, Typography } from "@mui/material";
import React from "react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckGreenIcon } from '../../images/Icon-check-circle-green.svg';
import imageback from '../../images/img-fond-fonce.svg';
import './SuccessNewRequestComponent.scss';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { RouteApp } from "../../common/model/RouteApp";

interface SuccessNewRequestComponentProps {

}

const SuccessNewRequestComponent: FunctionComponent<SuccessNewRequestComponentProps> = () => {

  const navigate = useNavigate();

  return (
    <Box
      className="page-container"
      sx={{
        backgroundImage: `url(${imageback})`
      }}
      display={'flex'}
    >
      <Container className="container">
        <Paper className="success-new-request-container">
          <Typography className="success-new-request-title" variant="h5" component="h2">Votre demande à bien été transmise</Typography>
          <CheckGreenIcon className="success-new-request-check-icon" />
          <Typography className="success-new-request-message" variant="body1" component="p">
            Vous recevrez prochainement une proposition commerciale ou une demande de clarification dans votre espace dédié
          </Typography>
          <Button
            onClick={() => navigate(RouteApp.ESTIMATES)}
            variant="contained" className="success-new-request-back-button"
            startIcon={<KeyboardBackspaceIcon />}
          >
            Retour
          </Button>
        </Paper>
      </Container>
    </Box>
  );
}

export default SuccessNewRequestComponent;