import { Paper, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import './ItemMenu.scss';


export interface ItemMenuProps {
  title: string;
  linkIcon: string;
  link: string;
}

const ItemMenu: FunctionComponent<ItemMenuProps> = (props: ItemMenuProps) => {

  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(props.link)}
    >
      <Paper className='item-menu-container' sx={{ borderRadius: 5,}}>
        <Typography className='item-menu-title' variant='h5' component="h2">
          {props.title}
        </Typography>
        <img className='item-menu-icon' src={require('../../images/' + props.linkIcon)} alt={props.linkIcon} />
      </Paper>
    </div>
  );
}

export default ItemMenu;