import { Pagination } from "@mui/material";
import React from "react";
import { FunctionComponent } from "react";

interface PaginationComponentProps {
  nbTotalPage: number;
  currentPage: number;
  onChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const PaginationComponent: FunctionComponent<PaginationComponentProps> = (props: PaginationComponentProps) => {

  function onChangePage(event: React.ChangeEvent<unknown>, page: number) {
    props.onChangePage(event, page);
  }

  return (
    <Pagination
      count={props.nbTotalPage} //nb total page
      hideNextButton={false}
      hidePrevButton={false}
      onChange={onChangePage}
      page={props.currentPage} //page courante
      showFirstButton
      showLastButton
      siblingCount={2} //nb de page toujours visible avant et apres la page courante
      shape="rounded"
      sx={{
        width: 'fit-content',
        margin: 'auto',
        '& .MuiPagination-ul .Mui-selected': {
          backgroundColor: '#E8403B'
        },
        '& .MuiPagination-ul li button:hover': {
          backgroundColor: '#E8403B'
        },
        '& .MuiPagination-ul li button': {
          backgroundColor: 'white',
          padding: 3
        },
        '& .MuiPagination-ul li div': {
          color: 'white'
        },
      }}
    />
  );
}

export default PaginationComponent;