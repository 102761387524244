import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { initialStateReducer, IStateReducer } from "../../common/model/IStateReducer";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { ResponseToken } from "../model/ResponseToken";
import { invalidateToken } from "./authAction";

export const initialAuthData: ResponseToken = {
    token: '',
    refresh_token: ''
}

export const initialAuthState: IStateReducer<ResponseToken> = {
  ...initialStateReducer,
  data: initialAuthData
}

const authSlice = createSlice({
  name:'auth',
  initialState: initialAuthState,
  reducers: {
    setLoginStart(state: IStateReducer<ResponseToken>) {
      state.isLoader = true;
      state.error = undefined;
      state.isSuccess = false;
      return state;
    },
    setLogin(state: IStateReducer<ResponseToken>, action: PayloadAction<ResponseToken>) {
      state.data = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      return state;
    },
    setLoginError(state: IStateReducer<ResponseToken>, action: PayloadAction<AxiosError<ResponseMessage>>) {
      state.isLoader = false;
      state.error = action.payload;
      state.isSuccess = false;
      return state;
    }
  },
  extraReducers(builder) {
    builder.addCase(invalidateToken.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
    });
    builder.addCase(invalidateToken.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isSuccess = true;
    });
    builder.addCase(invalidateToken.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
    });
  },
});

export default authSlice;