import { Card, Grid, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { RouteApp } from "../../common/model/RouteApp";
import './ItemDemandComponent.scss';
import { Estimate } from "../model/Estimate";
import { Role } from "../../common/model/User";
import { useSelector } from "react-redux";
import { RootState } from "../../common/redux/store";

interface ItemDemandComponentProps {
  demand: Estimate;
}

const ItemDemandComponent: FunctionComponent<ItemDemandComponentProps> = (props: ItemDemandComponentProps) => {

  const navigate = useNavigate();

  const roleUserConnected: Role | undefined = useSelector((state: RootState) => state.userConnected.user?.roles ? state.userConnected.user?.roles[0] : undefined);

  function getClassNameByStatus(): string {
    if (props.demand.status?.id === 1 || props.demand.status?.id === 2)
      return 'demand';
    if (props.demand.status?.id === 3)
      return 'analysis';
    return 'demand';
  }

  return (
    <Card onClick={() => navigate(RouteApp.ESTIMATES + RouteApp.PRICE_REQUEST_DETAIL + "/" + props.demand.id)} className={getClassNameByStatus() + ' item-container'} sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}>
      <Grid container width={'90%'}>
        <Grid item xs={3.5} md={2.5}>
          <Typography className='title'>Date commande</Typography>
        </Grid>

        <Grid item xs={3.5} md={2.5} className='title'>
          <Typography className='title'>N° Demande</Typography>
        </Grid>

        <Grid item xs={5} md={7} className='title'>
          <Typography className='title'>Statut</Typography>
        </Grid>


        <Grid item xs={3.5} md={2.5}>
          <Typography className='data'>{props.demand.created_at}</Typography>
        </Grid>

        <Grid item xs={3.5} md={2.5}>
          <Typography className='data'>{props.demand.estimate_number}</Typography>
        </Grid>

        <Grid item xs={5} md={7}>
          <Typography className='data'>{props.demand.status?.name}</Typography>
        </Grid>

      </Grid>
      <Typography className="voir">Voir</Typography>
    </Card>
  );
}

export default ItemDemandComponent;