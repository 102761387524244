import { Paper, Typography, Box, TextField, Grid } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Role } from "../../common/model/User";
import { RootState } from "../../common/redux/store";

interface QuestionAnswerComponentProps {
  clarification: string;
  response: string;
  isDisabledResponse: boolean;
  setNewMessage: React.Dispatch<React.SetStateAction<string>>;
}

const QuestionAnswerComponent: FunctionComponent<QuestionAnswerComponentProps> = (props: QuestionAnswerComponentProps) => {

  const roleUserConnected: Role | undefined = useSelector((state: RootState) => state.userConnected.user?.roles ? state.userConnected.user?.roles[0] : undefined);

  function showResponse(): boolean {
    if (roleUserConnected && (roleUserConnected === Role.Prospect || roleUserConnected === Role.Customer || roleUserConnected === Role.SuperCustomer))
      return true;
    if (roleUserConnected && (roleUserConnected === Role.Admin || roleUserConnected === Role.Commercial) && props.isDisabledResponse)
      return true;
    return false;
  }

  return (
    <Paper className="price-request-detail-clarification-request-container">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" component="h2" className="price-request-detail-information-title">
            Demande de clarification
          </Typography>
          <Box display={"flex"}>
            <TextField
              className="price-request-detail-clarification-request-textarea"
              multiline
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              maxRows={4}
              value={props.clarification}
              disabled={true}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          {showResponse() &&
            <>
              <Typography variant="h6" component="h2" className="price-request-detail-information-title">
                Réponse
              </Typography>
              <Box display={"flex"}>
                <TextField
                  className="price-request-detail-clarification-request-textarea"
                  multiline
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                  }}
                  maxRows={4}
                  value={props.response}
                  disabled={props.isDisabledResponse}
                  onChange={(e) => props.setNewMessage(e.target.value)}
                />
              </Box>
            </>
          }
        </Grid>
      </Grid>
    </Paper>
  );
}

export default QuestionAnswerComponent;