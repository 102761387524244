import React, { useEffect } from "react";
import "./StrengthMeter.scss";

interface StrengthMeterProps {
  password: string;
  actions: (childData?: string) => Promise<void>;
  whereItUsed: string;
}

interface PasswordIndicator {
  strength: number;
  val: '' | 'faible' | 'moyen' | 'bon' | 'fort';
}

const StrengthMeter = (props: StrengthMeterProps) => {

  const pwdValidate = props.password;

  function initPwdChecker(): PasswordIndicator | null {
    let pwdCheck = 0;
    let validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "[@$!%*?&.]"];

    validateRegex.forEach((regex, i) => {
      if (new RegExp(regex).test(pwdValidate)) {
        pwdCheck += 1;
      }
    });
    if (pwdValidate.length > 7) {
      pwdCheck += 1;
    }

    switch (pwdCheck) {
      case 0:
        return {
          strength: 0,
          val: "",
        };
      case 1:
        return {
          strength: 1,
          val: "faible",
        };
      case 2:
        return {
          strength: 2,
          val: "moyen",
        };
      case 3:
        return {
          strength: 3,
          val: "bon",
        };
      case 4:
        return {
          strength: 3,
          val: "bon",
        };
      case 5:
        return {
          strength: 4,
          val: "fort",
        };
      default:
        return null;
    }
  };

  useEffect(() => {
    props.actions(initPwdChecker()?.val);
  }, [props.password]);

  return (

    <>
      {
        props.password.length > 0 &&

        <div className={"wrapper wrapper-" + props.whereItUsed}>
          <progress
            className={`pwd-checker-bar strength-${initPwdChecker()?.val}`}
            value={initPwdChecker()?.strength}
            max="4"
          />
          <br />
          <div className="pwd-label">
            {props.password && (
              <div>
                <p className={`label label-color-${props.whereItUsed} strength-${initPwdChecker()?.val}`}>
                  Niveau de sécurité :
                  <strong>{" " + initPwdChecker()?.val} </strong>
                </p>
              </div>
            )}
          </div>
        </div>
      }
    </>
  );
};
export default StrengthMeter;
