import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Avatar, Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from '../../login/redux/authAction';
import { Role, User } from '../model/User';
import { useAppDispatch } from '../redux/reduxHooks';
import { RootState } from '../redux/store';
import { stringService } from '../service/stringService';
import Popup from './Popup';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { RouteApp } from '../model/RouteApp';

export default function Header() {

  const userConnected: User | undefined = useSelector((state: RootState) => state.userConnected.user);

  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(false);
  const [isDisplayPopupDeconnexion, setisDisplayPopupDeconnexion] =
    useState<boolean>(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (pathname === RouteApp.LOGIN || pathname === RouteApp.FORGOT_PASSWORD ||
      pathname.includes(RouteApp.CHANGE_PASSWORD) || pathname === RouteApp.REGISTRATION) {
      setIsHeaderVisible(false);
    } else {
      setIsHeaderVisible(true);
    }
  }, [pathname]);

  function onLogOut() {
    dispatch(logout());
    navigate(RouteApp.LOGIN);
    setisDisplayPopupDeconnexion(false);
  }

  const [isAvailableToManageUsers, setisAvailableToManageUsers] = useState<boolean>(false);
  const [isAvailableToOrders, setisAvailableToOrders] = useState<boolean>(false);

  useEffect(() => {
    if (userConnected?.roles && userConnected?.roles[0] === Role.Admin) {
      setisAvailableToManageUsers(true);
    }
    else {
      setisAvailableToManageUsers(false);
    }
    if (userConnected?.roles && (userConnected?.roles[0] === Role.Admin || userConnected?.roles[0] === Role.Prospect || userConnected?.roles[0] === Role.Commercial)) {
      setisAvailableToOrders(false);
    }
    else {
      setisAvailableToOrders(true);
    }
  }, [userConnected]);

  return (
    <>
      {
        isHeaderVisible ? (
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
              <Toolbar sx={{ backgroundColor: '#30353f' }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Box
                      className='header-logo'
                      onClick={() => navigate(RouteApp.DASHBOARD)}
                      component="img"
                      src={require('../../images/logo-aeroflex-sans-fond.png')}
                      sx={{ textAlign: 'left', cursor: 'pointer', marginY: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4} display={'flex'} alignItems= 'center' textAlign= 'center' justifyContent= 'center'>
                    <Box
                      component="img"
                      src={require('../../images/LOGO-3-QUADRI-BLANC.png')}
                      sx={{ width: 200, height: 60 }}
                    />
                  </Grid>
                  <Grid item xs={4} display= 'flex' alignItems= 'center' justifyContent= 'flex-end'>
                    <Box display={'flex'} alignItems= 'center'>
                      <Avatar sx={{
                        width: 60, height: 60
                      }}>
                        <AccountCircleIcon fontSize="large" sx={{ color: 'red', bgcolor: 'white', width: 60, height: 60 }} />
                      </Avatar>
                      <Typography variant="h6" component="div" sx={{ marginLeft: 1, fontWeight: 'bold' }}>
                        {userConnected ? stringService.capitalizeFirstLetter(userConnected.first_name) + ' ' + stringService.capitalizeFirstLetter(userConnected.last_name) : null}
                      </Typography>
                      <React.Fragment>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                          <IconButton
                            onClick={handleClick}
                            size="large"
                            sx={{ ml: 2, color: "white" }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                          >
                            <MenuIcon fontSize="large" />
                          </IconButton>
                        </Box>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: 'visible',
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              mt: 1.5,
                              '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                          {
                            isAvailableToOrders &&
                            <MenuItem onClick={() => navigate(RouteApp.ORDERS)}>
                              <ListItemIcon>
                                <AssignmentTurnedInIcon fontSize="small" />
                              </ListItemIcon>
                              Mes commandes
                            </MenuItem>
                          }

                          <MenuItem onClick={() => navigate(RouteApp.MY_ACCOUNT)}>
                            <ListItemIcon>
                              <PersonOutlineIcon fontSize="small" />
                            </ListItemIcon>
                            Mon compte
                          </MenuItem>
                          {
                            isAvailableToManageUsers &&
                            <MenuItem onClick={() => navigate(RouteApp.MANAGE_USERS)}>
                              <ListItemIcon>
                                <SupervisorAccountIcon fontSize="small" />
                              </ListItemIcon>
                              Administration
                            </MenuItem>
                          }
                          <MenuItem onClick={() => setisDisplayPopupDeconnexion(true)}>
                            <ListItemIcon>
                              <Logout fontSize="small" />
                            </ListItemIcon>
                            Déconnexion
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    </Box>  
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
        ) : null
      }

      <Popup
        open={isDisplayPopupDeconnexion}
        title={'Êtes vous sûr de vouloir vous déconnecter ?'}
        onClose={() => setisDisplayPopupDeconnexion(false)}
        onCancel={() => setisDisplayPopupDeconnexion(false)}
        titleButtonCancel={"Annuler"}
        onValidation={onLogOut}
        titleButtonValidation={"Valider"}
      />

    </>
  )
}
