import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, DialogContent, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import './Popup.scss';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  message?: string;
  title?: string;
  onValidation?: () => void;
  titleButtonValidation?: string;
  onCancel?: () => void;
  titleButtonCancel?: string;
}

export default function Popup(props: PopupProps) {
  return (
    <Dialog open={props.open} className='popup-background'>

      <DialogTitle className='popup-header'>
        {props.title}
        <IconButton aria-label="close" onClick={props.onClose} className='popup-close-button'>
          <HighlightOffIcon sx={{ color: 'white' }} fontSize='large' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {
          props.message ?
            <p className='popup-message'>{props.message}</p>
            :
            null
        }
        <div className='popup-actions-button'>
          {
            props.onCancel ?
              <Button onClick={props.onCancel} color="error" variant="outlined" className='popup-cancel-button'>{props.titleButtonCancel}</Button>
              :
              null
          }
          {
            props.onValidation ?
              <Button onClick={props.onValidation} variant="outlined" className='popup-validate-button'>{props.titleButtonValidation}</Button>
              :
              null
          }
        </div>
      </DialogContent>
    </Dialog>
  )
}
