import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { api, RouteAPI } from "../../common/service/apiService";
import { FilterOrder, ResponsePaginedOrders } from "../model/Order";
import { OrderDetail } from "../model/OrderDetail";

export const getPaginedOrders = createAsyncThunk<ResponsePaginedOrders, number>(
  "orders/getPaginedOrders",
  async (numPage, thunkAPI) => {
      try {
        const nbResultPerPage = 8;
        const response = await api(RouteAPI.ORDER + "/" + numPage + "/" + nbResultPerPage, 'GET');
        return response.data as ResponsePaginedOrders;
      } catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
      }
  }
)

export const getFilteredPaginedOrders = createAsyncThunk<ResponsePaginedOrders, FilterOrder>(
  "orders/getFilteredPaginedOrders",
  async (filterOrder, thunkAPI) => {
      try {
        const nbResultPerPage = filterOrder.nbResultPerPage;
        const bodyRequest = {
          property: filterOrder.property === 'empty' ? null : filterOrder.property,
          value: filterOrder.value,
          start_date: filterOrder.start_date,
          end_date: filterOrder.end_date,
        }
        const response = await api(RouteAPI.ORDER + '/' + filterOrder.type + '/' + filterOrder.numPage + "/" + nbResultPerPage, 'POST', bodyRequest);
        return response.data as ResponsePaginedOrders;
      } catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
      }
  }
)

export const getOrderById = createAsyncThunk<OrderDetail, number>(
  "orders/getOrderById",
  async (idOrder, thunkAPI) => {
      try {
        const response = await api(RouteAPI.ORDER + "/" + idOrder, 'GET');
        return response.data as OrderDetail;
      } catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
      }
  }
)