import { Box, Button, Container, TextField } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../common/component/CustomSnackbar";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteApp } from "../../common/model/RouteApp";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { RootState } from "../../common/redux/store";
import { resetPassword } from "../../common/redux/usersActions";
import { usersSetResponseMessage } from "../../common/redux/usersSlice";
import { stringService } from "../../common/service/stringService";
import imageback from '../../images/img-fond-fonce.svg';
import './ForgotPasswordPage.scss';

interface ForgotPasswordPageProps {

}

const ForgotPasswordPage: FunctionComponent<ForgotPasswordPageProps> = () => {

  const navigate = useNavigate();

  const [isEmailValid, setisEmailValid] = useState<boolean>(false);
  const [email, setemail] = useState<string>('');

  const [snackbarMessage, setsnackbarMessage] = useState<ResponseMessage | undefined>(undefined);
  const [openSnackbar, setopenSnackbar] = useState<boolean>(false);

  function onChangeEmail(value: string) {
    setemail(value);
    setisEmailValid(stringService.isValidMail(value));
  }

  const dispatch = useAppDispatch();

  const usersSelector = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (usersSelector.error) {
      setsnackbarMessage(usersSelector.error.response?.data)
      setopenSnackbar(true)
    }

    if (usersSelector.data.responseMessage) {
      setsnackbarMessage(usersSelector.data.responseMessage)
      setopenSnackbar(true)
      dispatch(usersSetResponseMessage(undefined));
    }
  }, [usersSelector]);

  function handleCloseSnackbar() {
    setsnackbarMessage(undefined);
    setopenSnackbar(false);
    if (usersSelector.error) {
      navigate(RouteApp.FORGOT_PASSWORD);
    }

    if (usersSelector.data.responseMessage) {
      navigate(RouteApp.LOGIN);
    }
  }

  return (
    <Box
      className="page-container"
      display='flex'
      sx={{
        backgroundImage: `url(${imageback})`
      }}
    >
      <Container className="container" sx={{ display: 'flex' }}>
        <div className="forgot-content">

          <h1 className="forgot-title">Vous avez oublié votre mot de passe?</h1>
          <p className="forgot-paragraph">Merci de saisir votre adresse mail afin que nous puissions vous envoyer un lien de réinitialisation de mot de passe</p>

          <div className="forgot-email">
            <TextField
              fullWidth
              type='email'
              error={!isEmailValid && email.length > 0}
              id="email"
              label="Email"
              helperText={isEmailValid ? '' : email.length > 0 ? 'Le format de l’adresse email est invalide' : ''}
              value={email}
              onChange={(e) => onChangeEmail(e.target.value)}
            />
          </div>

          <div className="forgot-button">
            <Button variant="outlined" color="error" onClick={() => navigate(RouteApp.LOGIN)}>Annuler</Button>
            <Button variant="outlined" disabled={!isEmailValid} color="inherit" onClick={() => dispatch(resetPassword(email))}>Valider</Button>
          </div>

        </div>
      </Container>
      <CustomSnackbar
        open={snackbarMessage !== undefined && openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        message={snackbarMessage?.message ? snackbarMessage.message : ''}
        severity={snackbarMessage?.status === 200 ? "success" : "error"}
        position={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Box>
  );
}

export default ForgotPasswordPage;