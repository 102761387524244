import { Autocomplete, AutocompleteInputChangeReason, Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../common/component/CustomSnackbar";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteApp } from "../../common/model/RouteApp";
import { TypeDoc } from "../../common/model/TypeDoc";
import { Role, User } from "../../common/model/User";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { RootState } from "../../common/redux/store";
import { getFilteredCompanies } from "../../common/redux/usersActions";
import { setCompanies } from "../../common/redux/usersSlice";
import { ReactComponent as PlusIcon } from '../../images/Icon-plus-circle-noir.svg';
import imageback from '../../images/img-fond-fonce.svg';
import ItemDoc from "../../qualityDocuments/component/ItemDoc";
import { CompanyForDocument, DocumentToDisplay } from "../../qualityDocuments/model/Document";
import { getAllCustomerDocument } from "../../qualityDocuments/redux/documentsActions";
import { documentsSetResponseMessage, setDocument } from "../../qualityDocuments/redux/documentsSlice";
import './CustomerDocumentsPage.scss';

interface CustomerDocumentsPageProps {

}

const CustomerDocumentsPage: FunctionComponent<CustomerDocumentsPageProps> = () => {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const userConnected: User | undefined = useSelector((rootState: RootState) => rootState.userConnected.user)

  const documentsSelector = useSelector((rootState: RootState) => rootState.documents);

  const usersSelector = useSelector((state: RootState) => state.users);

  const [snackbarMessage, setsnackbarMessage] = useState<ResponseMessage | undefined>(undefined);
  const [openSnackbar, setopenSnackbar] = useState<boolean>(false);

  const [company, setcompany] = useState<CompanyForDocument | null>(null);
  const [searchValueCompany, setsearchValueCompany] = useState<string>('');

  useEffect(() => {
    dispatch(setCompanies([]));
  }, []);

  useEffect(() => {
    if (!isUserAdminOrCommercial()) {
      dispatch(getAllCustomerDocument());
    }
  }, [userConnected]);

  function isUserAdminOrCommercial(): boolean {
    if (userConnected?.roles && ((userConnected?.roles[0] === Role.Admin) || (userConnected?.roles[0] === Role.Commercial)))
      return true;
    return false
  }

  useEffect(() => {
    if (documentsSelector.data.responseMessage) {
      setsnackbarMessage(documentsSelector.data.responseMessage);
      setopenSnackbar(true);
      dispatch(documentsSetResponseMessage(undefined));
      if (documentsSelector.requestType === 'DELETE') {
        dispatch(getAllCustomerDocument(company?.id ? company.id : undefined));
      }
    }
  }, [documentsSelector]);

  useEffect(() => {
    if (company?.id) {
      dispatch(getAllCustomerDocument(company.id));
    }
  }, [company]);

  function onChangeSearchValueCompany(searchValueCompany: string, reason: AutocompleteInputChangeReason) {
    if (reason === "clear") {
      dispatch(setCompanies([]));
    }
    if (searchValueCompany.length > 2 && reason !== "reset") {
      dispatch(getFilteredCompanies(searchValueCompany))
    }
    setsearchValueCompany(searchValueCompany);
  }

  function handleCloseSnackbar() {
    setopenSnackbar(false)
  }

  function displayData(): boolean {
    if (isUserAdminOrCommercial() && company) {
      return true;
    }
    if (!isUserAdminOrCommercial()) {
      return true;
    }
    return false;
  }

  return (
    <Box
      className="page-container"

      sx={{
        backgroundImage: `url(${imageback})`,
      }}

    >
      <Container className="container">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Typography className='title-page' variant='h4' component="h1">
            Documents Client
          </Typography>

          {
            userConnected && userConnected.roles && (userConnected.roles[0] === Role.Admin || userConnected.roles[0] === Role.Commercial) &&
            <Button
              variant="text"
              className="btn-nouvelle-demande"
              sx={{
                backgroundColor: 'white',
                marginY: 'auto',
                textTransform: 'none',
              }}

              onClick={() => {
                dispatch(setCompanies([]));
                dispatch(setDocument(undefined));
                navigate(RouteApp.CUSTOMER_DOCUMENTS + RouteApp.EDIT);
              }}
            >
              <PlusIcon className="icon-plus" />Ajouter document
            </Button>
          }

        </Box>

        {
          isUserAdminOrCommercial() &&
          <Autocomplete
            value={company}
            onChange={(event: any, newValue: CompanyForDocument | null) => {
              setcompany(newValue);
            }}
            inputValue={searchValueCompany}
            onInputChange={(event, newInputValue, reason) => {
              onChangeSearchValueCompany(newInputValue, reason);
            }}
            id="company"
            className="select-company"
            options={usersSelector.data.companies}
            getOptionLabel={(company) => company.name || ""}
            sx={{
              backgroundColor: 'white', width: '350px',
              margin: '4em 0', borderRadius: '10px',
              '& .MuiFormLabel-root': {
                backgroundColor: 'white',
                paddingX: 2,
                width: company === null ? undefined : '27.1rem',
                marginLeft: company === null ? undefined : '-0.9rem',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              },
              '& label.Mui-focused': {
                backgroundColor: 'transparent',
                color: 'white',
                top: '-20px',
                left: '-15px',
                fontSize: '24px',
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                }
              },
            }}
            renderInput={(params) => <TextField {...params} label="Société" variant="outlined" />}
          />
        }

        {
          displayData() && documentsSelector.data.documents.length === 0 && documentsSelector.isSuccess ?
            <h1 className="title-no-doc-available">Aucun document disponible</h1>
            :
            displayData() &&
            <Box className="list-doc-container">
              <Grid className="list-item-doc-container" container spacing={2} marginTop='auto'>
                {
                  documentsSelector.data.documents.map((doc: DocumentToDisplay, indice: number) => {
                    return (
                      <Grid key={indice} item lg={3} md={6} sm={6} xs={12}>
                        <ItemDoc doc={doc} typeDoc={TypeDoc.CUSTOMER} />
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Box>
        }

        <CustomSnackbar
          open={snackbarMessage !== undefined && openSnackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          message={snackbarMessage?.message ? snackbarMessage.message : ''}
          severity={snackbarMessage?.status === 200 ? "success" : "error"}
          position={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </Container>
    </Box>
  );
}

export default CustomerDocumentsPage;