import { Box, Container, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import imageback from '../../images/img-fond-clair.svg';
import DashboardMenu from "./DashboardMenu";
import DashboardOrders from "./DashboardOrders";
import './Dashboard.scss';
import { useSelector } from "react-redux";
import { RootState } from "../../common/redux/store";
import { UserConnectedState } from "../../common/redux/userConnectedSlice";
import { Role } from "../../common/model/User";

interface DashBoardProps {

}

const Dashboard: FunctionComponent<DashBoardProps> = () => {

  const user: UserConnectedState = useSelector((state: RootState) => state.userConnected);

  function isLastOrderAvailable(): boolean {
    if (user.user && user.user.roles) {
      if (user.user.roles[0] === Role.Customer || user.user.roles[0] === Role.SuperCustomer) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <Box
        className="page-container"
        sx={{
          height: '100%',
          backgroundImage: `url(${imageback})`,
          backgroundSize: 'cover',
        }}
      >
        <Container className="container">

          <Typography className='title-page title-page-dashboard' variant='h4' component="h1">
            Mon Compte
          </Typography>

          <DashboardMenu />

          {
            isLastOrderAvailable() &&
            <>
              <Typography className='title-page title-page-dashboard' variant='h4' component="h1">
                Dernières commandes
              </Typography>
              <DashboardOrders />
            </>
          }

        </Container>

      </Box>
    </>
  );
}

export default Dashboard;