import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { api, RouteAPI } from "../../common/service/apiService";
import { DocumentForEdition, DocumentToDisplay, DocumentToUpdate } from "../model/Document";

function generateFormDataFromDocument(doc: DocumentForEdition): FormData {
  const formData = new FormData();
  formData.append('name', doc.name);
  if (doc.picture)
    formData.append('picture', doc.picture);
  formData.append('file', doc.file);
  if (doc.company) {
    formData.append('company', doc.company.id.toString());
  }
  return formData;
}

export const createQualityDocument = createAsyncThunk<ResponseMessage, DocumentForEdition>(
  "documents/createQualityDocument",
  async (document, thunkAPI) => {
    try {
      const response = await api(RouteAPI.DOCUMENT + '/1', 'POST', generateFormDataFromDocument(document));
      return response.data as ResponseMessage;
    } catch (error) {
      return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
)

export const createCustomerDocument = createAsyncThunk<ResponseMessage, DocumentForEdition>(
  "documents/createCustomerDocument",
  async (document, thunkAPI) => {
    try {
      const response = await api(RouteAPI.DOCUMENT + '/2', 'POST', generateFormDataFromDocument(document));
      return response.data as ResponseMessage;
    } catch (error) {
      return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
)

export const deleteDocument = createAsyncThunk<ResponseMessage, number>(
  "documents/deleteDocument",
  async (idDocument, thunkAPI) => {
    try {
      const response = await api(RouteAPI.DOCUMENT + '/' + idDocument, 'DELETE');
      return response.data as ResponseMessage;
    } catch (error) {
      return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
)

export const getAllQualityDocument = createAsyncThunk(
  "documents/getAllQualityDocument",
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await api(RouteAPI.DOCUMENT + RouteAPI.QUALITY, 'GET');
      return response.data as DocumentToDisplay[];
    } catch (error) {
      return rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
)

export const getAllCustomerDocument = createAsyncThunk(
  "documents/getAllCustomerDocument",
  async (idCompany: number | undefined, { rejectWithValue }) => {
    try {
      const route = idCompany ? RouteAPI.DOCUMENT + RouteAPI.CUSTOMER + '/' + idCompany : RouteAPI.DOCUMENT + RouteAPI.CUSTOMER;
      const response = await api(route, 'GET');
      return response.data as DocumentToDisplay[];
    } catch (error) {
      return rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
)

export const getDocumentById = createAsyncThunk<DocumentToDisplay, number>(
  "documents/getDocumentById",
  async (idDocument, thunkAPI) => {
    try {
      const response = await api(RouteAPI.DOCUMENT + '/' + idDocument, 'GET');
      return response.data as DocumentToDisplay;
    } catch (error) {
      return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
)

export const updateDocument = createAsyncThunk<ResponseMessage, DocumentToUpdate>(
  "documents/updateDocument",
  async (doc, thunkAPI) => {
    try {
      const {id, formData} = doc;
      const response = await api(RouteAPI.DOCUMENT + RouteAPI.EDIT + '/' + id, 'POST', formData);
      return response.data as ResponseMessage;
    } catch (error) {
      return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
  }
)