import { Grid, Paper } from "@mui/material";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { RouteApp } from "../../common/model/RouteApp";
import { Order } from "../model/Order";
import './ListItemOrder.scss';

interface ListItemOrderProps {
  order: Order;
}

const ListItemOrder: FunctionComponent<ListItemOrderProps> = (props: ListItemOrderProps) => {
  const navigate = useNavigate();

  return (
    <Paper className="list-item-order-container">
      <Grid container
        onClick={() => navigate(RouteApp.ORDERS + RouteApp.ORDER_DETAIL + "/" + props.order.id)}
        sx={{
          cursor: "pointer"
        }}
      >
        <Grid item xs={2} className="list-item-order-title">
          Date commande
        </Grid>
        <Grid item xs={2} className="list-item-order-title">
          N° commande
        </Grid>
        <Grid item xs={2} className="list-item-order-title">
          Statut
        </Grid>
        <Grid item xs={2} className="list-item-order-title">
          Date de livraison
        </Grid>
        <Grid item xs={4} className="list-item-order-title">
          Montant TTC
        </Grid>

        <Grid item xs={2} className="list-item-order-data">
          {props.order.order_date}
        </Grid>
        <Grid item xs={2} className="list-item-order-data">
          {props.order.reference}
        </Grid>
        <Grid item xs={2} className="list-item-order-data">
          {props.order.status}
        </Grid>
        <Grid item xs={2} className="list-item-order-data">
          {props.order.delivery_date}
        </Grid>
        <Grid item xs={4} className="list-item-order-data">
          {props.order.total_ttc + " €"}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ListItemOrder;