import { Grid, Link, Paper, Typography } from "@mui/material";
import React from "react";
import { FunctionComponent } from "react";
import { ReactComponent as PdfIcon } from '../../images/icone-pdf.svg';
import { Estimate } from "../model/Estimate";

interface ItemEstimateComponentProps {
  estimate: Estimate,
}

const ItemEstimateComponent: FunctionComponent<ItemEstimateComponentProps> = (props: ItemEstimateComponentProps) => {
  return (
    <Paper className={'item-container'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Grid container width={'90%'}>
        <Grid item xs={3} className='title'>
          Date commande
        </Grid>

        <Grid item xs={4} className='title'>
          N° Demande
        </Grid>

        <Grid item xs={5} className='title'>
          Ref. Devis
        </Grid>


        <Grid item xs={3} className='data'>
          {props.estimate.created_at}
        </Grid>

        <Grid item xs={4} className='data'>
          {props.estimate.estimate_number}
        </Grid>

        <Grid item xs={5} className='data'>
          {props.estimate.reference}
        </Grid>

      </Grid>

      <Link href={props.estimate.url_estimate} underline="none" target="_blank" rel="noreferrer" display={'flex'}>
        <Typography className="voir">Ouvrir</Typography>
        <PdfIcon />
      </Link>

    </Paper>
  );
}

export default ItemEstimateComponent;