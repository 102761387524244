import { Grid } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import ItemMenu, { ItemMenuProps } from "./ItemMenu";
import './DashboardMenu.scss';
import { RouteApp } from "../../common/model/RouteApp";
import { useSelector } from "react-redux";
import { RootState } from "../../common/redux/store";
import { Role, User } from "../../common/model/User";

interface DashboardMenuProps {

}

const DashboardMenu: FunctionComponent<DashboardMenuProps> = () => {

  const userConnected: User | undefined = useSelector((rootState: RootState) => rootState.userConnected.user);

  const [listMenu, setlistMenu] = useState<ItemMenuProps[]>([]);

  useEffect(() => {
    setlistMenu(
      getListMenuByProfil()
    )
  }, [userConnected]);

  function getListMenuByProfil(): ItemMenuProps[] {
    const role = userConnected?.roles ? userConnected?.roles[0] : undefined;
    if (role) {
      if (role === Role.Admin || role === Role.Commercial) {
        return [
          {
            title: 'Mes commandes',
            linkIcon: 'icone-commande.svg',
            link: RouteApp.ORDERS
          },
          {
            title: 'Devis',
            linkIcon: 'icone-devis.svg',
            link: RouteApp.ESTIMATES
          },
          {
            title: 'Documents qualité',
            linkIcon: 'icone-document.svg',
            link: RouteApp.QUALITY_DOCUMENTS
          },
          {
            title: 'Documents Clients',
            linkIcon: 'icone-document.svg',
            link: RouteApp.CUSTOMER_DOCUMENTS
          },
        ]
      }
      if (role === Role.SuperCustomer) {
        return [
          {
            title: 'Mes commandes',
            linkIcon: 'icone-commande.svg',
            link: RouteApp.ORDERS
          },
          {
            title: 'Devis',
            linkIcon: 'icone-devis.svg',
            link: RouteApp.ESTIMATES
          },
          {
            title: 'Documents qualité',
            linkIcon: 'icone-document.svg',
            link: RouteApp.QUALITY_DOCUMENTS
          },
          {
            title: 'Documents Clients',
            linkIcon: 'icone-document.svg',
            link: RouteApp.CUSTOMER_DOCUMENTS
          },
        ]
      }
      if (role === Role.Customer) {
        return [
          {
            title: 'Mes commandes',
            linkIcon: 'icone-commande.svg',
            link: RouteApp.ORDERS
          },
          {
            title: 'Devis',
            linkIcon: 'icone-devis.svg',
            link: RouteApp.ESTIMATES
          },
          {
            title: 'Documents qualité',
            linkIcon: 'icone-document.svg',
            link: RouteApp.QUALITY_DOCUMENTS
          },
          {
            title: 'Documents Clients',
            linkIcon: 'icone-document.svg',
            link: RouteApp.CUSTOMER_DOCUMENTS
          },
        ]
      }
      if (role === Role.Prospect) {
        return [
          {
            title: 'Devis',
            linkIcon: 'icone-devis.svg',
            link: RouteApp.ESTIMATES
          },
          {
            title: 'Documents qualité',
            linkIcon: 'icone-document.svg',
            link: RouteApp.QUALITY_DOCUMENTS
          },
        ]
      }
    }
    return [];
  }

  return (
    <Grid className="dashboard-menu-container" container spacing={5}>
      {
        listMenu.map((itemMenu: ItemMenuProps, indice: number) => {
          return (
            <Grid key={indice} item xs={6} sm={6} md={'auto'} sx={{ flex: 1 }} className="dashboard-menu-grid">
              <ItemMenu title={itemMenu.title}
                link={itemMenu.link} linkIcon={itemMenu.linkIcon}
              />
            </Grid>
          )
        })
      }
    </Grid>
  );
}

export default DashboardMenu;