import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { initialStateReducer, IStateReducer } from "../../common/model/IStateReducer";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { emptyResponsePaginedOrders, ResponsePaginedOrders } from "../model/Order";
import { OrderDetail } from "../model/OrderDetail";
import { getFilteredPaginedOrders, getOrderById, getPaginedOrders } from "./ordersActions";

export interface OrdersData {
  responsePaginedOrders: ResponsePaginedOrders;
  orderDetail?: OrderDetail;
  responseMessage?: ResponseMessage;
}

const initialOrdersData: OrdersData = {
  responsePaginedOrders: emptyResponsePaginedOrders,
  responseMessage: undefined
}

const initialOrdersState: IStateReducer<OrdersData> = {
  ...initialStateReducer,
  data: initialOrdersData
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrdersState,
  reducers: {
    ordersSetResponseMessage: (state, action: PayloadAction<ResponseMessage | undefined>) => {
      state.data.responseMessage = action.payload
    },
  },
  extraReducers(builder) {
    // **************** PAGINED ORDERS LIST *****************
    builder.addCase(getPaginedOrders.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getPaginedOrders.fulfilled, (state, action) => {
      state.data.responsePaginedOrders = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getPaginedOrders.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** FILTERED PAGINED ORDERS LIST *****************
    builder.addCase(getFilteredPaginedOrders.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(getFilteredPaginedOrders.fulfilled, (state, action) => {
      state.data.responsePaginedOrders = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(getFilteredPaginedOrders.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** GET ORDER BY ID *****************
    builder.addCase(getOrderById.pending, (state) => {
      state.data.orderDetail = undefined;
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getOrderById.fulfilled, (state, action) => {
      state.data.orderDetail = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getOrderById.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
  },
})

// Action creators are generated for each case reducer function
export const { ordersSetResponseMessage } = ordersSlice.actions

export default ordersSlice