import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FunctionComponent } from "react";
import { ReactComponent as EyeIcon } from '../../images/icon-eye.svg';
import './TechnicalSheetComponent.scss';
import { DataSheet } from "../model/OrderDetail";

const isResoFullHD: boolean = window.innerWidth > 1280;

interface TechnicalSheetComponentProps {
  currentTechnicalSheet: DataSheet;
}

const TechnicalSheetComponent: FunctionComponent<TechnicalSheetComponentProps> = (props: TechnicalSheetComponentProps) => {
  return (
    <Paper sx={{
      width: '100%',
      backgroundColor: 'white',
      marginBottom: isResoFullHD ? 5 : 2,
      maxHeight: isResoFullHD ? '' : '10rem'
    }}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Box
            component="img"
            src={props.currentTechnicalSheet.picture ? props.currentTechnicalSheet.picture : undefined}
            sx={{
              width: isResoFullHD ? 200 : 130,
              height: isResoFullHD ? 200 : 130,
              border: '2px solid black',
              borderRadius: '10px',
              margin: isResoFullHD ? 2 : 1
            }}
          >
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h4" component="h1"
            sx={{
              fontWeight: 'bold',
              fontSize: isResoFullHD ? undefined : '33px',
              marginY: isResoFullHD ? 2 : 1
            }}
          >
            {props.currentTechnicalSheet.reference}
          </Typography>
          <Typography variant="h6" component="h3"
            sx={{
              fontWeight: 'bold',
              marginTop: isResoFullHD ? 2 : 1,
              color: 'gray',
              fontSize: isResoFullHD ? undefined : '20px'
            }}
          >
            {props.currentTechnicalSheet.name}
          </Typography>
        </Grid>
        <Grid item xs={3} display={'flex'}>
          <Box marginY={'auto'} display={'flex'}>
            <Typography variant="h6" component="h3"
              sx={{
                fontWeight: 'bold',
                color: 'gray',
                maxWidth: isResoFullHD ? undefined : '130px'
              }}
            >
              Voir la fiche technique
            </Typography>
            <Button href={props.currentTechnicalSheet.path} target="_blank">
              <EyeIcon className="eye-icon" />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default TechnicalSheetComponent;