import { Box, Grid } from "@mui/material";
import React, { FunctionComponent } from "react";
import { Order, OrderStatus } from "../model/Order";
import CardOrder from "./CardOrder";
import ListItemOrder from "./ListItemOrder";

interface OrdersByStateProps {
  isInListDisplayMode: boolean;
  state: OrderStatus.CURRENT | OrderStatus.PAST;
  orders: Order[];
}

const OrdersByState: FunctionComponent<OrdersByStateProps> = (props: OrdersByStateProps) => {

  return (
    <Box sx={{ marginBottom: 3 }}>
      {
        props.isInListDisplayMode ?
          <Box>
            {
              props.orders.map((order: Order, indice: number) => {
                return <ListItemOrder key={indice} order={order} />
              })
            }
          </Box>
          :
          <Grid container spacing={4}>
            {
              props.orders.map((order: Order, indice: number) => {
                return (
                  <Grid key={indice} item xs={3}>
                    <CardOrder order={order} />
                  </Grid>
                )
              })
            }
          </Grid>

      }

    </Box>
  );
}

export default OrdersByState;