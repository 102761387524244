import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { initialStateReducer, IStateReducer } from "../../common/model/IStateReducer";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { DocumentToDisplay } from "../model/Document";
import { createCustomerDocument, createQualityDocument, deleteDocument, getAllCustomerDocument, getAllQualityDocument, getDocumentById, updateDocument } from "./documentsActions";

export interface DocumentsData {
  documents: DocumentToDisplay[];
  document?: DocumentToDisplay;
  responseMessage?: ResponseMessage;
}

const initialDocumentsData: DocumentsData = {
  documents: [],
  document: undefined,
  responseMessage: undefined
}

const initialDocumentsState: IStateReducer<DocumentsData> = {
  ...initialStateReducer,
  data: initialDocumentsData
}

export const documentsSlice = createSlice({
  name: 'documents',
  initialState: initialDocumentsState,
  reducers: {
    documentsSetResponseMessage: (state, action: PayloadAction<ResponseMessage | undefined>) => {
      state.data.responseMessage = action.payload
    },
    setDocument: (state, action: PayloadAction<DocumentToDisplay | undefined>) => {
      state.data.document = action.payload
    },
  },
  extraReducers(builder) {
    // **************** CREATION DOC QUALITE *****************
    builder.addCase(createQualityDocument.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(createQualityDocument.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(createQualityDocument.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** CREATION DOC CLIENT *****************
    builder.addCase(createCustomerDocument.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(createCustomerDocument.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(createCustomerDocument.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** GET ALL DOC QUALITE *****************
    builder.addCase(getAllQualityDocument.pending, (state) => {
      state.data.documents = [];
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getAllQualityDocument.fulfilled, (state, action) => {
      state.data.documents = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getAllQualityDocument.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** GET ALL DOC CLIENTS *****************
    builder.addCase(getAllCustomerDocument.pending, (state) => {
      state.data.documents = [];
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getAllCustomerDocument.fulfilled, (state, action) => {
      state.data.documents = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getAllCustomerDocument.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** SUPPRIMER DOCUMENT *****************
    builder.addCase(deleteDocument.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'DELETE';
    });
    builder.addCase(deleteDocument.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'DELETE';
    });
    builder.addCase(deleteDocument.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'DELETE';
    });
    // **************** GET DOC BY ID *****************
    builder.addCase(getDocumentById.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getDocumentById.fulfilled, (state, action) => {
      state.data.document = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getDocumentById.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** UPDATE DOCUMENT *****************
    builder.addCase(updateDocument.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(updateDocument.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(updateDocument.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
  },
})

// Action creators are generated for each case reducer function
export const { documentsSetResponseMessage, setDocument } = documentsSlice.actions

export default documentsSlice