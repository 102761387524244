import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { ResponseToken } from "../../login/model/ResponseToken";
import { refreshToken } from "../../login/redux/authAction";
import { RouteApp } from "../model/RouteApp";
import { RouteAPI, api } from "../service/apiService";
import { storageService } from "../service/storageService";

const axiosInstance: AxiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig<any>) => {   
    if (config.url && config.url !== RouteAPI.LOGIN && config.url !== RouteAPI.REFRESH_TOKEN) {
      const token = storageService.getToken();
      if (token && config.headers) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    if (config.headers) {
      if ((config.url === RouteAPI.ESTIMATE && config.method === 'post') || (config.url?.includes(RouteAPI.DOCUMENT) && config.method === 'post') ||
        (config.data instanceof FormData && (config.data.has('files[]') || config.data.has('files') || config.data.has('file')))) {
        config.headers['Content-Type'] = 'multipart/form-data';
      }
      else {
        config.headers['Content-Type'] = 'application/json';
      }
    }

    return config;
  },
  (error: any) => Promise.reject(error)

);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Si la réponse est réussie, la retourner directement 
    return response;
  },
  async (error: AxiosError) => {
    // Vérifier si l'erreur est une erreur d'autorisation (status code 401) 
    if (error.response?.status === 401 && (error.config?.url !== RouteAPI.REFRESH_TOKEN && error.config?.url !== RouteAPI.LOGIN)) {
      const originalRequest = error.config;
      // Récupérer le refreshToken
      if (storageService.getRefreshToken()) {
        
        try {
          // Obtenir un nouveau token d'accès en utilisant le refreshToken 
          const responseToken = await refreshToken();
          const { token, refresh_token } = responseToken.data as ResponseToken;
          storageService.setToken(token);
          storageService.setRefreshToken(refresh_token);
        } catch (error) {
          storageService.removeToken();
          storageService.removeRefreshToken();
          storageService.setErrorMessageRefreshToken();
          window.location.href = RouteApp.LOGIN;
          return;
        }

        if (originalRequest) {
          const postMethod: Method = "post";
          const putMethod: Method = "put";
          if (originalRequest.method === 'get' || originalRequest.method === 'delete') {
            return api(originalRequest.url!, originalRequest.method);
          }
          else if (originalRequest.method === 'post' || originalRequest.method === 'put') {
            return api(originalRequest.url!, originalRequest.method === postMethod ? postMethod : putMethod, originalRequest?.data);
          }
        }
      } else {
        // Si le refreshToken est introuvable, rediriger vers la page de connexion ou effectuer d'autres actions nécessaires  
        window.location.href = RouteApp.LOGIN;
      }
    }
    else if (error.config?.url === RouteAPI.REFRESH_TOKEN) {
      storageService.removeToken();
      storageService.removeRefreshToken();
      storageService.setErrorMessageRefreshToken();
      window.location.href = RouteApp.LOGIN;
    }
    // Si ce n'est pas une erreur d'autorisation, retourner simplement l'erreur 
    return Promise.reject(error);
  }
);

export default axiosInstance;