import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import CustomSnackbar from '../../common/component/CustomSnackbar';
import StrengthMeter from '../../common/component/StrengthMeter';
import { ResponseMessage } from '../../common/model/ResponseMessage';
import { RouteApp } from '../../common/model/RouteApp';
import { DataChangePassword, User } from '../../common/model/User';
import { useAppDispatch } from '../../common/redux/reduxHooks';
import { RootState } from '../../common/redux/store';
import { changePassword, changePasswordWithIdUser } from '../../common/redux/usersActions';
import { setIsSuccess, usersSetResponseMessage } from '../../common/redux/usersSlice';
import './ChangePasswordPage.scss';

interface ChangePasswordPageProps {

}

const ChangePasswordPage: FunctionComponent<ChangePasswordPageProps> = () => {

  const userConnected: User | undefined = useSelector((state: RootState) => state.userConnected.user);

  const navigate = useNavigate();

  const { token } = useParams();

  const [password1, setpassword1] = useState<string>('');
  const [password2, setpassword2] = useState<string>('');

  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const [isErrorPassword1, setErrorPassword1] = useState<string | null>(null);
  const [isErrorPassword2, setErrorPassword2] = useState<string | null>(null);

  const [snackbarMessage, setsnackbarMessage] = useState<ResponseMessage | undefined>(undefined);
  const [openSnackbar, setopenSnackbar] = useState<boolean>(false);

  function handleClickShowPassword(numPassword: number) {
    if (numPassword === 1)
      setShowPassword1((show) => !show);
    else
      setShowPassword2((show) => !show);
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, numPassword: number) => {
    let password = e.target.value;
    if (numPassword === 1) {
      setpassword1(e.target.value);
      setErrorPassword1(null);
    }
    else {
      setpassword2(e.target.value);
      setErrorPassword2(null);
    }

    let caps, small, num, specialSymbol;
    if (password.length < 8) {
      if (numPassword === 1)
        setErrorPassword1(
          "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial : @ $ ! % * ? & ."
        );
      return;
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/[@$!%*?&.]/g) || []).length;
      if (numPassword === 1) {
        if (caps < 1) {
          setErrorPassword1("Ajouter une lettre majuscule");
          return;
        } else if (small < 1) {
          setErrorPassword1("Ajouter une lettre minuscule");
          return;
        } else if (num < 1) {
          setErrorPassword1("Ajouter un nombre");
          return;
        } else if (specialSymbol < 1) {
          setErrorPassword1("Ajouter un caractère spécial : @ $ ! % * ? & .");
          return;
        }
      }
    }
  };

  const [isStrong1, initRobustPassword1] = useState<string | null>(null);
  const initPwdInput1 = async (childData?: string) => {
    initRobustPassword1(childData ? childData : '');
  };

  const dispatch = useAppDispatch();

  const usersSelector = useSelector((state: RootState) => state.users);

  function onClickValidate() {
    if (password1 !== password2) {
      setErrorPassword2("Les mots de passe ne correspondent pas")
    }
    else {
      if (token) {
        const dataChangePassword: DataChangePassword = {
          plainPassword: {
            plainPassword: {
              first: password1,
              second: password2
            }
          },
          token: token
        }
        setsnackbarMessage(undefined);
        setopenSnackbar(false);
        dispatch(changePassword(dataChangePassword));
      }
      else {
        const dataChangePassword: DataChangePassword = {
          plainPassword: {
            plainPassword: {
              first: password1,
              second: password2
            }
          },
          idUser: usersSelector.data.user?.id
        }
        setsnackbarMessage(undefined);
        setopenSnackbar(false);
        dispatch(changePasswordWithIdUser(dataChangePassword));
      }
    }
  }

  useEffect(() => {
    if (usersSelector.error) {
      setsnackbarMessage(usersSelector.error.response?.data);
      setopenSnackbar(true);
    }
    else if (usersSelector.isSuccess && usersSelector.requestType !== 'GET') {
      setsnackbarMessage(usersSelector.data.responseMessage);
      setopenSnackbar(true);
      setTimeout(() => {
        dispatch(setIsSuccess(false));
        dispatch(usersSetResponseMessage(undefined));
        navigate(RouteApp.LOGIN);
      }, 2500);
    }
  }, [usersSelector]);

  function handleCloseSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
    setopenSnackbar(false);
  }

  function onClickCancel() {
    if (userConnected)
      navigate(RouteApp.MY_ACCOUNT)
    else
      navigate(RouteApp.LOGIN)
  }

  return (
    <>
      <div className="change-password-container">
        <div className="change-password-content">
          <h1>Changement de mot de passe</h1>

          <div className="change-password-input">

            <FormControl variant='outlined' sx={{ width: '45%', marginRight: 2 }}>
              <InputLabel required htmlFor="mdp1">Nouveau mot de passe</InputLabel>
              <OutlinedInput
                id="mdp1"
                type={showPassword1 ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(1)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Nouveau mot de passe"
                error={isErrorPassword1 !== null}
                value={password1}
                onChange={(e) => onChangePassword(e, 1)}
              />
              {isErrorPassword1 !== null &&
                <FormHelperText sx={{ color: 'red' }}>
                  {isErrorPassword1}
                </FormHelperText>
              }
              <StrengthMeter password={password1} actions={initPwdInput1} whereItUsed="change-password" />
            </FormControl>

            <FormControl variant='outlined' sx={{ width: '45%', marginLeft: 2 }}>
              <InputLabel required htmlFor="mdp2">Confirmation nouveau mot de passe</InputLabel>
              <OutlinedInput
                id="mdp2"
                type={showPassword2 ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(2)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirmation nouveau mot de passe"
                error={isErrorPassword2 !== null}
                value={password2}
                onChange={(e) => onChangePassword(e, 2)}
              />
              {isErrorPassword2 !== null &&
                <FormHelperText sx={{ color: 'red' }}>
                  {isErrorPassword2}
                </FormHelperText>
              }
            </FormControl>
          </div>

          <div className="change-password-button">
            <Button variant="outlined" color="error" onClick={() => onClickCancel()}>Annuler</Button>
            <Button variant="outlined" color='inherit' onClick={() => onClickValidate()} disabled={password1.length < 8 || isStrong1 !== "fort"}>Valider</Button>
          </div>
          <CustomSnackbar
            open={snackbarMessage !== undefined && openSnackbar}
            handleCloseSnackbar={handleCloseSnackbar}
            message={snackbarMessage?.message ? snackbarMessage.message : ''}
            severity={snackbarMessage?.status === 200 ? "success" : "error"}
            position={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={snackbarMessage?.status === 200 ? 3000 : 6000}
          />
        </div>
      </div>
    </>
  );
}

export default ChangePasswordPage;