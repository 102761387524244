import { Autocomplete, AutocompleteInputChangeReason, Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, SxProps, TextField, Theme, Typography } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomSnackbar from "../../common/component/CustomSnackbar";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteApp } from "../../common/model/RouteApp";
import { Company, ErrorsFormUser, getRoleAsText, Role, User, UserToEdit } from "../../common/model/User";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { RootState } from "../../common/redux/store";
import { addUser, getFilteredCompanies, getUser, updateUser } from "../../common/redux/usersActions";
import { setCompanies } from "../../common/redux/usersSlice";
import { stringService } from "../../common/service/stringService";
import imageback from '../../images/img-fond-fonce.svg';
import './EditUserPage.scss';

const isResoFullHD: boolean = window.innerWidth > 1280;

interface EditUserPageProps {

}

const EditUserPage: FunctionComponent<EditUserPageProps> = () => {

  const { idUser } = useParams();

  const navigate = useNavigate();

  const sxInputText: SxProps<Theme> = {
    backgroundColor: 'white',
    marginBottom: 2,
    '& label.Mui-focused': {
      color: '#6C6C6C',
    },
    '& .MuiFilledInput-underline:after': {
      borderColor: '#6C6C6C',
    },
    '& .MuiFormHelperText-root': { 
      color: '#BB0101', position: 'absolute', top: '55px' 
    }
  }

  const sxSelect: SxProps<Theme> = {
    backgroundColor: 'white',
    marginX: 'auto',
    marginTop: isResoFullHD ? 6 : 3,
    minWidth: 300,
    '& label.Mui-focused': {
      color: '#6C6C6C',
    },
    '& .MuiFilledInput-underline:after': {
      borderColor: '#6C6C6C',
    }
  }
  const initialErrorFormUser: ErrorsFormUser = {
    company: false,
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
    username: false
  }

  const [error, seterror] = useState<ResponseMessage | undefined>(undefined);

  const [lastname, setlastname] = useState<string>('');
  const [firstname, setfirstname] = useState<string>('');

  const [isEmailValid, setisEmailValid] = useState<boolean>(false);
  const [email, setemail] = useState<string>('');
  const [username, setusername] = useState<string>('');
  const [company, setcompany] = useState<Company | null>(null);
  const [searchValueCompany, setsearchValueCompany] = useState<string>('');
  const [phoneNumber, setphoneNumber] = useState<string>('');
  const [role, setRole] = React.useState<Role>(Role.Customer);
  const [errorFormUser, setErrorFormUser] = useState<ErrorsFormUser>(initialErrorFormUser);

  const dispatch = useAppDispatch();

  const usersSelector = useSelector((state: RootState) => state.users);

  const handleChangeRole = (event: SelectChangeEvent) => {
    setRole(event.target.value as Role);
  };

  function onChangeEmail(value: string) {
    setemail(value);
    setisEmailValid(stringService.isValidMail(value));
  }


  useEffect(() => {
    if (idUser) {
      dispatch(getUser(Number.parseInt(idUser)))
    }
  }, [idUser]);

  useEffect(() => {
    if (usersSelector.data.user) {
      const user = usersSelector.data.user;
      setlastname(user.last_name);
      setfirstname(user.first_name);
      setemail(user.email);
      setisEmailValid(true);
      setusername(user.username);
      setcompany(user.company instanceof String ? null : user.company!);
      setphoneNumber(user.phone);
      setRole(user.roles ? user.roles[0] : Role.Customer);
    }
    if (usersSelector.error) {
      seterror(usersSelector.error.response?.data);
    }
    if (usersSelector.data.responseMessage?.status === 200) {
      navigate(RouteApp.MANAGE_USERS);
    }
  }, [usersSelector]);

  const userConnected: User | undefined = useSelector((state: RootState) => state.userConnected.user);

  function getLbUser(): string {
    if (userConnected?.roles && userConnected.roles[0] === Role.Commercial) {
      return 'un client';
    }
    return 'un utilisateur';
  }

  function onChangeSearchValueCompany(searchValueCompany: string, reason: AutocompleteInputChangeReason) {
    if (reason === "clear") {
      dispatch(setCompanies([]));
    }
    if (searchValueCompany.length > 2 && reason !== "reset") {
      dispatch(getFilteredCompanies(searchValueCompany))
    }
    setsearchValueCompany(searchValueCompany);
  }

  function onClickValidate() {
    const userToEdit: UserToEdit = {
      id: idUser ? Number.parseInt(idUser) : undefined,
      first_name: firstname,
      last_name: lastname,
      email: email,
      username: username,
      company: company ? company.id : null,
      phone: phoneNumber,
      roles: [role]
    }
    if (!isUserInvalid()) {
      if (idUser) {
        dispatch(updateUser(userToEdit));
      }
      else {
        dispatch(addUser(userToEdit));
      }
    }
  }

  function isUserInvalid(): boolean {
    let isError = false;
    let errors = errorFormUser;
    if (firstname === '') {
      isError = true;
      errors = {
        ...errors,
        first_name: true
      };
    }
    if (lastname === '') {
      isError = true;
      errors = {
        ...errors,
        last_name: true
      };
    }
    if (username === '') {
      isError = true;
      errors = {
        ...errors,
        username: true
      };
    }
    if (company === null) {
      isError = true;
      errors = {
        ...errors,
        company: true
      };
    }
    if (phoneNumber === '') {
      isError = true;
      errors = {
        ...errors,
        phone: true
      };
    }
    if (email === '' || !isEmailValid) {
      isError = true;
      errors = {
        ...errors,
        email: true
      };
    }
    setErrorFormUser(errors);
    return isError;
  }

  function handleCloseSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
    seterror(undefined);
  }

  return (
    <Box
      className="page-container"
      sx={{
        backgroundImage: `url(${imageback})`
      }}
    >
      <Container className="container">
        <Typography className='title-page' variant='h4' component="h1">
          {idUser ? 'Modifier ' + getLbUser() : 'Ajouter ' + getLbUser()}
        </Typography>

        <Paper className="edit-user-page-paper-container">
          <Typography variant='h4' component="h1" className="edit-user-page-title">
            INFORMATIONS DE CONNEXION
          </Typography>
          <Box
            sx={{
              paddingX: 40,
              paddingY: isResoFullHD ? 5 : 2
            }}
          >
            <Grid container columnSpacing={10} rowSpacing={isResoFullHD ? 5 : 2}>
              <Grid item xs={6}>
                <TextField
                  id="nom" label="Nom" variant="filled" required sx={sxInputText} fullWidth
                  value={lastname}
                  onChange={(e) => {
                    setlastname(e.target.value);
                    setErrorFormUser({ ...errorFormUser, last_name: initialErrorFormUser.last_name })
                  }}
                  error={errorFormUser.first_name}
                  helperText={errorFormUser.last_name ? 'Nom obligatoire' : ''}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="prenom" label="Prénom" variant="filled" required sx={sxInputText} fullWidth
                  value={firstname}
                  onChange={(e) => { 
                    setfirstname(e.target.value);
                    setErrorFormUser({ ...errorFormUser, first_name: initialErrorFormUser.first_name })
                  }}
                  error={errorFormUser.first_name}
                  helperText={errorFormUser.first_name ? 'Prénom obligatoire' : ''}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField id="email" type='email'
                  label="Email" variant="filled" required
                  sx={sxInputText} fullWidth
                  error={errorFormUser.email}
                  helperText={errorFormUser.email ? !isEmailValid ? 'L’adresse email est invalide' : 'Adresse email obligatoire' : ''}
                  value={email}
                  onChange={(e) => { 
                    onChangeEmail(e.target.value);
                    setErrorFormUser({ ...errorFormUser, email: initialErrorFormUser.email })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="username" label="Identifiant" variant="filled" required sx={sxInputText} fullWidth
                  value={username}
                  onChange={(e) => {
                    setusername(e.target.value);
                    setErrorFormUser({ ...errorFormUser, username: initialErrorFormUser.username })
                  }}
                  error={errorFormUser.username}
                  helperText={errorFormUser.username ? 'Identifiant obligatoire' : ''}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  value={company}
                  onChange={(event: any, newValue: Company | null) => {
                    setcompany(newValue);
                  }}
                  inputValue={searchValueCompany}
                  onInputChange={(event, newInputValue, reason) => {
                    onChangeSearchValueCompany(newInputValue, reason);
                    setErrorFormUser({ ...errorFormUser, company: initialErrorFormUser.company })
                  }}
                  id="company"
                  options={usersSelector.data.companies}
                  getOptionLabel={(company) => company.name || ""}
                  renderInput={(params) => <TextField required {...params} label="Société" variant="filled" sx={sxInputText} error={errorFormUser.company} fullWidth helperText={errorFormUser.company ? 'Société obligatoire' : ''} />}
                />

              </Grid>
              <Grid item xs={6}>
                <MuiPhoneNumber
                  id="phoneNumber" label="N°Téléphone" required
                  variant="filled" sx={sxInputText} fullWidth defaultCountry="fr"
                  onChange={(event: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setphoneNumber(event.toString());
                    setErrorFormUser({ ...errorFormUser, phone: initialErrorFormUser.phone })
                  }}
                  value={phoneNumber}
                  helperText={errorFormUser.phone ? 'N° Téléphone obligatoire' : ''}
                  error={errorFormUser.phone}
                />
              </Grid>

            </Grid>

            <Box display={'flex'}>
              <FormControl variant="standard" sx={sxSelect}>
                <InputLabel id="select-label">Rôle</InputLabel>
                <Select
                  required
                  labelId="select-label"
                  id="select"
                  value={role}
                  onChange={handleChangeRole}
                  label="Rôle"
                >
                  <MenuItem value={Role.Admin}>{getRoleAsText(Role.Admin)}</MenuItem>
                  <MenuItem value={Role.Commercial}>{getRoleAsText(Role.Commercial)}</MenuItem>
                  <MenuItem value={Role.SuperCustomer}>{getRoleAsText(Role.SuperCustomer)}</MenuItem>
                  <MenuItem value={Role.Customer}>{getRoleAsText(Role.Customer)}</MenuItem>
                  {
                    (userConnected?.roles && userConnected?.roles[0] !== Role.Admin) ||
                      (userConnected?.roles && userConnected?.roles[0] === Role.Admin && idUser !== undefined) ?
                      <MenuItem value={Role.Prospect}>{getRoleAsText(Role.Prospect)}</MenuItem>
                      :
                      null
                  }
                </Select>
              </FormControl>
            </Box>
            {

              <Box className="edit-user-page-btn-container" sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="outlined" className="edit-user-page-cancel-btn" onClick={() => navigate(RouteApp.MANAGE_USERS)}>Annuler</Button>
                <Button variant="outlined" className="edit-user-page-validate-btn" onClick={onClickValidate}>Valider</Button>
              </Box>
            }
          </Box>
        </Paper>
      </Container>
      <CustomSnackbar
        open={error ? true : false}
        handleCloseSnackbar={handleCloseSnackbar}
        message={error?.message ? error.message : ''}
        severity={"error"}
        position={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
      />
    </Box>
  );
}

export default EditUserPage;