import { VisibilityOff, Visibility } from '@mui/icons-material';
import { Alert, Box, Button, FormControl, IconButton, InputAdornment, Link, OutlinedInput, TextField, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/redux/reduxHooks';
import { SignInData } from '../model/SignInData';
import { login } from '../redux/authAction';
import './LoginPage.scss'
import imageback from '../../images/img-fond-fonce.svg';
import { useNavigate } from 'react-router';
import { RouteApp } from '../../common/model/RouteApp';
import { storageService } from '../../common/service/storageService';

interface IState {
  signInData: SignInData,
  showPassword: boolean,
}

const LoginPage: FunctionComponent = () => {

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const tokens = useAppSelector(state => state.auth);

  const [signinData, setsigninData] = useState<IState>({
    signInData: {
      username: '',
      password: ''
    },
    showPassword: false
  });

  const [errorMessageRefreshToken, seterrorMessageRefreshToken] = useState<string | null>(null);

  function connexion() {
    seterrorMessageRefreshToken(null);
    dispatch(login(signinData.signInData));
  }

  function onChangeLogin(username: string) {
    setsigninData({
      ...signinData,
      signInData: {
        ...signinData.signInData,
        username: username
      }
    })
  }

  function onChangePassword(password: string) {
    setsigninData({
      ...signinData,
      signInData: {
        ...signinData.signInData,
        password: password
      }
    })
  }

  useEffect(() => {
    if (storageService.getErrorMessageRefreshToken()) {
      seterrorMessageRefreshToken(storageService.getErrorMessageRefreshToken());
      storageService.removeErrorMessageRefreshToken();
    }
  }, []);

  useEffect(() => {
    if (tokens.isSuccess && tokens.data.token.length > 0) {
      navigate(RouteApp.DASHBOARD);
    }
  }, [tokens]);

  function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (event.key === 'Enter') connexion()
  }

  return (
    <Box className='page-container'
      display={'flex'}
      sx={{
        backgroundImage: `url(${imageback})`,
      }}
    >
      <Box
        margin={'auto'}
        paddingX={10}
        paddingY={4}
        sx={{
          backgroundColor: 'white',
          width: '20%',
          borderRadius: 10
        }}
      >
        <Box display={'flex'}>
          <img className='login-logo' src={require('../../images/logo-aeroflex-fond-blanc.jpg')} alt="logo aeroflex" />
          <Box
            component="img"
            src={require('../../images/LOGO-2-TRAIT-NOIR.png')}
            sx={{ width: 150, height: 90 }}
          />
        </Box>
        <Typography
          variant='h4'
          component='h2'
          sx={{
            marginY: 4
          }}
        >
          S'authentifier
        </Typography>
        {
          (tokens.error || errorMessageRefreshToken) ?
            <Alert severity="error" className='errorMessage'>{tokens.error ? tokens.error.response?.data.message : errorMessageRefreshToken}</Alert>
            :
            null
        }

        <TextField
          fullWidth label="Identifiant"
          id="outlined-start-adornment" variant="outlined"
          onChange={(e) => onChangeLogin(e.target.value)}
        />

        <FormControl fullWidth variant="outlined" sx={{ marginY: 5 }}>
          <InputLabel htmlFor="outlined-adornment-password">
            Mot de passe
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={signinData.showPassword ? 'text' : 'password'}
            value={signinData.signInData.password}
            onChange={(e) => onChangePassword(e.target.value)}
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setsigninData({
                    ...signinData,
                    showPassword: !signinData.showPassword
                  })}
                  edge="end"
                >
                  {signinData.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Mot de passe"
          />
        </FormControl>

        <Link
          href={RouteApp.FORGOT_PASSWORD}
          sx={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Mot de passe oublié ?
        </Link>

        <Button
          color="error" variant="contained"
          onClick={connexion}
          sx={{
            display: "block",
            margin: "30px auto",
          }}
        >
          Connexion
        </Button>

        <p>
          Vous n'avez pas encore de compte ? &nbsp;
          <Link href={RouteApp.REGISTRATION}>S'inscrire</Link>
        </p>

      </Box>
    </Box>
  );
}

export default LoginPage;