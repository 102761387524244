import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import { Details, OrderLine } from "../model/OrderDetail";
import './OrderProductsDetailsComponent.scss';

const isResoFullHD: boolean = window.innerWidth > 1280;

interface OrderProductsDetailsComponentProps {
  orderLine: OrderLine[];
}

const OrderProductsDetailsComponent: FunctionComponent<OrderProductsDetailsComponentProps> = (props: OrderProductsDetailsComponentProps) => {

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const sxAccordionSummary: SxProps<Theme> = {
    height: isResoFullHD ? '91px' : '60px',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
      marginRight: 3,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: 3,
    },
  }

  const sxBoxQuantityRounded: SxProps<Theme> = {
    background: '#E75A5A 0% 0% no-repeat padding-box',
    opacity: 1,
    width: '37px',
    height: '37px',
    borderRadius: '30px',
    display: 'flex',
  }

  const sxBoxQuantity: SxProps<Theme> = {
    width: isResoFullHD ? '37px' : '20px',
    height: isResoFullHD ? '37px' : '20px',
    display: 'flex',
  }

  const sxGridQuantity: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'flex-end'
  }

  const sxTextAccordionSummary: SxProps<Theme> = {
    textAlign: 'center',
    fontSize: isResoFullHD ? '20px' : '15px',
    color: '#707070'
  }

  return (
    <Box sx={{ overflowY: 'auto', maxHeight: isResoFullHD ? '340px' : '242px' }} className='product-container'>
      <Grid container spacing={1} sx={{ marginBottom: 1 }} className='product-header'>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          <Typography sx={{ textAlign: 'center', fontSize: isResoFullHD ? '20px' : '18px', fontWeight: 'bold' }}>Référence</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ textAlign: 'center', fontSize: isResoFullHD ? '20px' : '18px', fontWeight: 'bold' }}>Désignation</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ textAlign: 'end', fontSize: isResoFullHD ? '20px' : '18px', fontWeight: 'bold' }}>Quantité</Typography>
        </Grid>
      </Grid>

      {
        props.orderLine.map((orderLine: OrderLine, indice: number) => {
          return <Accordion
            expanded={expanded === orderLine.reference}
            onChange={handleChange(orderLine.reference)}
            key={indice}
          >
            <AccordionSummary
              aria-controls={orderLine.reference + "bh-content"}
              id={orderLine.reference + "bh-header"}
              sx={sxAccordionSummary}
            >
              <Grid container spacing={1} sx={{ fontSize: '10px'}}>
                <Grid item xs={1}>
                  {expanded === orderLine.reference ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={sxTextAccordionSummary}>{orderLine.reference}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={sxTextAccordionSummary}>{orderLine.designation}</Typography>
                </Grid>
                <Grid item xs={2} sx={sxGridQuantity}>
                  <Box sx={sxBoxQuantityRounded}>
                    <Typography sx={{ color: 'white', margin: 'auto' }}>{orderLine.quantity}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {
                orderLine.details.map((orderDetail: Details, key: number) => {
                  return <Grid container spacing={1} key={key}>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={sxTextAccordionSummary}>{orderDetail.reference}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography sx={sxTextAccordionSummary}>{orderDetail.designation}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={sxGridQuantity}>
                      <Box sx={sxBoxQuantity}>
                        <Typography margin='auto' sx={sxTextAccordionSummary}>{orderDetail.quantity}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                })
              }
            </AccordionDetails>
          </Accordion>
        })
      }
    </Box >
  );
}

export default OrderProductsDetailsComponent;