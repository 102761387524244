import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { initialStateReducer, IStateReducer } from "../model/IStateReducer";
import { ResponseMessage } from "../model/ResponseMessage";
import { Company, emptyResponsePaginedUsers, ResponsePaginedUsers, User } from "../model/User";
import { addUser, changePassword, changePasswordWithIdUser, deleteUser, getFilteredCompanies, getFilteredPaginedUsers, getPaginedUsers, getUser, register, resetPassword, updateUser } from "./usersActions";

export interface UsersData {
  user?: User;
  responsePaginedUsers: ResponsePaginedUsers;
  responseMessage?: ResponseMessage;
  companies: Company[];
}

const initialUsersData: UsersData = {
  user: undefined,
  responsePaginedUsers: emptyResponsePaginedUsers,
  responseMessage: undefined,
  companies: []
}

const initialUsersState: IStateReducer<UsersData> = {
  ...initialStateReducer,
  data: initialUsersData
}

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.data.user = action.payload
    },
    usersSetResponseMessage: (state, action: PayloadAction<ResponseMessage | undefined>) => {
      state.data.responseMessage = action.payload
    },
    usersSetError: (state, action: PayloadAction<AxiosError<ResponseMessage> | undefined>) => {
      state.error = action.payload
    },
    setCompanies: (state, action: PayloadAction<Company[]>) => {
      state.data.companies = action.payload
    },
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload
    }
  },
  extraReducers(builder) {
    // **************** INSCRIPTION *****************
    builder.addCase(register.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(register.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** REINITIALISATION DU MDP *****************
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(resetPassword.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** CHANGEMENT DE MDP *****************
    builder.addCase(changePassword.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(changePassword.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** CHANGEMENT DE MDP AVEC ID UTILISATEUR *****************
    builder.addCase(changePasswordWithIdUser.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(changePasswordWithIdUser.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(changePasswordWithIdUser.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** RECUPERATION LISTE UTILISATEURS *****************
    builder.addCase(getPaginedUsers.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getPaginedUsers.fulfilled, (state, action) => {
      state.data.responsePaginedUsers = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getPaginedUsers.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** RECUPERATION LISTE UTILISATEURS *****************
    builder.addCase(getFilteredPaginedUsers.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(getFilteredPaginedUsers.fulfilled, (state, action) => {
      state.data.responsePaginedUsers = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(getFilteredPaginedUsers.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** RECUPERATION LISTE DES SOCIETES *****************
    builder.addCase(getFilteredCompanies.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getFilteredCompanies.fulfilled, (state, action) => {
      state.data.companies = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getFilteredCompanies.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** AJOUTER UN UTILISATEUR *****************
    builder.addCase(addUser.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(addUser.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** RECUPERER UN UTILISATEUR *****************
    builder.addCase(getUser.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.data.user = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** MODIFIER UN UTILISATEUR *****************
    builder.addCase(updateUser.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'PUT';
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'PUT';
    });
    builder.addCase(updateUser.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'PUT';
    });
    // **************** DELETE UN UTILISATEUR *****************
    builder.addCase(deleteUser.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'DELETE';
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'DELETE';
    });
    builder.addCase(deleteUser.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'DELETE';
    });
  },
})

// Action creators are generated for each case reducer function
export const { setUser, usersSetResponseMessage, usersSetError, setCompanies, setIsSuccess } = usersSlice.actions

export default usersSlice