import React, { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ChangePasswordPage from "../../changePassword/component/ChangePasswordPage";
import CustomerDocumentsPage from "../../customerDocuments/component/CustomerDocumentsPage";
import Dashboard from "../../dashboard/component/Dashboard";
import ForgotPasswordPage from "../../forgotPassword/component/ForgotPasswordPage";
import LoginPage from "../../login/component/LoginPage";
import EditUserPage from "../../manageUsers/component/EditUserPage";
import ManageUsersPage from "../../manageUsers/component/ManageUsersPage";
import MyAccountPage from "../../myAccount/component/MyAccountPage";
import DatasheetsPage from "../../orders/component/DatasheetsPage";
import OrderDetailPage from "../../orders/component/OrderDetailPage";
import OrdersPage from "../../orders/component/OrdersPage";
import QualityDocumentsPage from "../../qualityDocuments/component/QualityDocumentsPage";
import NewRequestPage from "../../quotations/component/NewRequestPage";
import PriceRequestDetailPage from "../../quotations/component/PriceRequestDetail";
import QuotationsPage from "../../quotations/component/EstimatesPage";
import RegistrationPage from "../../registration/component/RegistrationPage";
import { Role, User } from "../model/User";
import { useAppDispatch } from "../redux/reduxHooks";
import { RootState } from "../redux/store";
import { setUserConnected } from "../redux/userConnectedSlice";
import { jwtService } from "../service/jwtService";
import { storageService } from "../service/storageService";
import Header from "./Header";
import "./MainRoutes.scss";
import NotFoundPage from "./NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import AddQualityDocumentsPage from "../../qualityDocuments/component/AddQualityDocumentsPage";
import AddCustomerDocumentsPage from "../../customerDocuments/component/AddCustomerDocumentsPage";
import { RouteApp } from "../model/RouteApp";

const MainRoutes: FunctionComponent = () => {

  const userConnected: User | undefined = useSelector((state: RootState) => state.userConnected.user);

  const dispatch = useAppDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  async function setUser() {
    const userFromToken: User = jwtService.getDecodedToken() as User;
    await dispatch(setUserConnected(userFromToken));

    navigate(location.pathname);
  }

  useEffect(() => {
    if (!userConnected && storageService.getToken()) {
      setUser();
    }
  }, []);

  return (
    <>
      <Header />
      <div className="page">
        <Routes>
          {/* ROUTES NE NECESSITANT PAS D ETRE CONNECTE */}
          {/* CONNEXION */}
          <Route path={RouteApp.LOGIN} element={<LoginPage />}></Route>
          {/* INSCRIPTION */}
          <Route path={RouteApp.REGISTRATION} element={<RegistrationPage />} />
          {/* MDP OUBLIE */}
          <Route path={RouteApp.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
          {/* MODIFER MDP */}
          <Route path={RouteApp.CHANGE_PASSWORD} element={<ChangePasswordPage />} />
          <Route path={RouteApp.CHANGE_PASSWORD + "/:token"} element={<ChangePasswordPage />} />

          <Route path={RouteApp.DASHBOARD} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <Dashboard />
            </PrivateRoute>
          } />

          {/* COMMANDES */}
          <Route path={RouteApp.ORDERS} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <OrdersPage />
            </PrivateRoute>
          } />
          <Route path={RouteApp.ORDERS + RouteApp.ORDER_DETAIL + "/:idOrder"} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <OrderDetailPage />
            </PrivateRoute>
          } />
          <Route path={RouteApp.ORDERS + RouteApp.ORDER_DETAIL + "/:idOrder" + RouteApp.DATASHEETS} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <DatasheetsPage />
            </PrivateRoute>
          } />

          {/* DEVIS */}
          <Route path={RouteApp.ESTIMATES} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <QuotationsPage />
            </PrivateRoute>
          } />
          <Route path={RouteApp.ESTIMATES + RouteApp.NEW_REQUEST} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <NewRequestPage />
            </PrivateRoute>
          } />
          <Route path={RouteApp.ESTIMATES + RouteApp.PRICE_REQUEST_DETAIL + "/:idPriceRequest"} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <PriceRequestDetailPage />
            </PrivateRoute>
          } />

          {/* DOCUMENTS QUALITE */}
          <Route path={RouteApp.QUALITY_DOCUMENTS} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <QualityDocumentsPage />
            </PrivateRoute>
          } />
          <Route path={RouteApp.QUALITY_DOCUMENTS + RouteApp.EDIT} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <AddQualityDocumentsPage />
            </PrivateRoute>
          } />
          <Route path={RouteApp.QUALITY_DOCUMENTS + RouteApp.EDIT + '/:idDoc'} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <AddQualityDocumentsPage />
            </PrivateRoute>
          } />

          {/* DOCUMENTS CLIENT */}
          <Route path={RouteApp.CUSTOMER_DOCUMENTS} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <CustomerDocumentsPage />
            </ PrivateRoute>
          } />
          <Route path={RouteApp.CUSTOMER_DOCUMENTS + RouteApp.EDIT} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <AddCustomerDocumentsPage />
            </PrivateRoute>
          } />
          <Route path={RouteApp.CUSTOMER_DOCUMENTS + RouteApp.EDIT + '/:idDoc'} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <AddCustomerDocumentsPage />
            </PrivateRoute>
          } />

          {/* MON COMPTE */}
          <Route path={RouteApp.MY_ACCOUNT} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <MyAccountPage />
            </ PrivateRoute>
          } />

          {/* GESTION UTILISATEURS */}
          <Route path={RouteApp.MANAGE_USERS} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <ManageUsersPage />
            </PrivateRoute>
          } />
          <Route path={RouteApp.MANAGE_USERS + RouteApp.EDIT_USER} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              <EditUserPage />
            </PrivateRoute>
          } />

          <Route path={RouteApp.MANAGE_USERS + RouteApp.EDIT_USER + '/:idUser'} element={
            <PrivateRoute roles={[Role.Admin, Role.Commercial, Role.SuperCustomer, Role.Customer, Role.Prospect]}>
              < EditUserPage />
            </PrivateRoute >
          } />

          <Route path="*" element={< NotFoundPage />}></Route >

        </Routes >
      </div >

    </>
  );
}

export default MainRoutes;