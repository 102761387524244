import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { api, RouteAPI } from "../../common/service/apiService";
import { Estimate, EstimateStatus, EstimateUpdateClarify, EstimateUpdateFiles, FilterEstimate, ResponsePaginedEstimates } from "../model/Estimate";

export const getPaginedEstimates = createAsyncThunk<ResponsePaginedEstimates, number>(
  "estimates/getPaginedEstimates",
  async (numPage, thunkAPI) => {
      try {
          const nbResultPerPage = 5;
          const response = await api(RouteAPI.ESTIMATE + "/" + numPage + "/" + nbResultPerPage, 'GET');
          return response.data as ResponsePaginedEstimates;
      } catch (error) {
          return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
      }
  }
)

export const getFilteredPaginedEstimates = createAsyncThunk<ResponsePaginedEstimates, FilterEstimate>(
    "estimates/getFilteredPaginedEstimates",
    async (filterEstimate, thunkAPI) => {
        try {
            const nbResultPerPage = filterEstimate.nbResultPerPage;
            const bodyRequest = {
                property: filterEstimate.property === 'empty' ? null : filterEstimate.property,
                value: filterEstimate.value,
                start_date: filterEstimate.start_date,
                end_date: filterEstimate.end_date,
            }
            const response = await api(RouteAPI.ESTIMATE + '/' + filterEstimate.type + '/' + filterEstimate.numPage + "/" + nbResultPerPage, 'POST', bodyRequest);
            return response.data as ResponsePaginedEstimates;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
  )

export const getPaginedDemands = createAsyncThunk<ResponsePaginedEstimates, number>(
    "estimates/getPaginedDemands",
    async (numPage, thunkAPI) => {
        try {
            const nbResultPerPage = 5;
            const response = await api(RouteAPI.ESTIMATE + RouteAPI.PRICE + "/" + numPage + "/" + nbResultPerPage, 'GET');
            return response.data as ResponsePaginedEstimates;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
  )

function generateFormDataFromEstimateObject(estimate: Estimate): FormData {
    const formData = new FormData();
    formData.append('product', estimate.product)
    formData.append('quantity', estimate.quantity.toString())
    for (let index = 0; index < estimate.files.length; index++) {
        formData.append("files[]", estimate.files[index]);    
    }
    formData.append('additional_information', estimate.additional_information)
    return formData;
}

export const createEstimate = createAsyncThunk<ResponseMessage, Estimate>(
    "estimates/create",
    async (estimate, thunkAPI) => {
        try {
            const response = await api(RouteAPI.ESTIMATE, 'POST', generateFormDataFromEstimateObject(estimate));
            return response.data as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const getDemandById = createAsyncThunk<Estimate, number>(
    "estimates/getDemandById",
    async (idDemand, thunkAPI) => {
        try {
            const response = await api(RouteAPI.ESTIMATE + '/' + idDemand, 'GET');
            return response.data as Estimate;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const updateClarify = createAsyncThunk<ResponseMessage, EstimateUpdateClarify>(
    "estimates/updateClarify",
    async (clarification, thunkAPI) => {
        try {
            const response = await api(RouteAPI.ESTIMATE + RouteAPI.EDIT + '/' + clarification.id, 'POST', {message: clarification.clarify});
            return response.data as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

function generateFormDataFromFiles(files: File[]): FormData {
    const formData = new FormData();
    if (files.length > 1) {
        for (let index = 0; index < files.length; index++) {
            formData.append("files[]", files[index]);    
        }
    }
    else {
        formData.append("files", files[0]);
    }
    return formData;
}

export const updateFiles = createAsyncThunk<ResponseMessage, EstimateUpdateFiles>(
    "estimates/updateFiles",
    async (clarification, thunkAPI) => {
        try {
            const response = await api(RouteAPI.ESTIMATE + RouteAPI.EDIT + '/' + clarification.id, 'POST', generateFormDataFromFiles(clarification.files));
            return response.data as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const assignRequest = createAsyncThunk<ResponseMessage, number>(
    "estimates/assignRequest",
    async (idDemand, thunkAPI) => {
        try {
            const response = await api(RouteAPI.ESTIMATE + RouteAPI.ASSIGN + '/' + idDemand, 'PUT');
            return response.data as ResponseMessage;
        } catch (error) {
            return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
)

export const getAllEstimateStatus = createAsyncThunk(
    "estimates/getAllEstimateStatus",
    async (data: undefined, {rejectWithValue}) => {
        try {
            const response = await api(RouteAPI.ESTIMATE_STATUS, 'GET');
            return response.data as EstimateStatus[];
        } catch (error) {
            return rejectWithValue(error as AxiosError<ResponseMessage>);
        }
    }
  )