import { AxiosError, Method } from "axios";
import { ResponseMessage } from "./ResponseMessage";

export interface IStateReducer<T> {
  isLoader: boolean;
  isSuccess?: boolean;
  requestType?: Method;
  data: T;
  error?: AxiosError<ResponseMessage>;
}

/**
 * Initial State Reducer
 */
 export const initialStateReducer = {
  isLoader: false,
  isSuccess: false,
  error: undefined,
};