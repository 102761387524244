import jwt_decode from "jwt-decode";
import { storageService } from './storageService';

export const jwtService = {
  getDecodedToken,  
}

function getDecodedToken() {
  if (storageService.getToken()) {
    return jwt_decode(storageService.getToken()!);
  }
  return null;
}