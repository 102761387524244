import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { initialStateReducer, IStateReducer } from "../../common/model/IStateReducer";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { emptyResponsePaginedEstimates, Estimate, EstimateStatus, ResponsePaginedEstimates } from "../model/Estimate";
import { assignRequest, createEstimate, getAllEstimateStatus, getDemandById, getFilteredPaginedEstimates, getPaginedDemands, getPaginedEstimates, updateClarify, updateFiles } from "./estimatesActions";

export interface EstimatesData {
  responsePaginedEstimates: ResponsePaginedEstimates;
  listEstimateStatus: EstimateStatus[]; 
  estimate?: Estimate;
  responseMessage?: ResponseMessage;
}

const initialEstimatesData: EstimatesData = {
  responsePaginedEstimates: emptyResponsePaginedEstimates,
  listEstimateStatus: [],
  estimate: undefined,
  responseMessage: undefined
}

const initialEstimatesState: IStateReducer<EstimatesData> = {
  ...initialStateReducer,
  data: initialEstimatesData
}

export const estimatesSlice = createSlice({
  name: 'estimates',
  initialState: initialEstimatesState,
  reducers: {
    estimatesSetResponseMessage: (state, action: PayloadAction<ResponseMessage | undefined>) => {
      state.data.responseMessage = action.payload
    },
  },
  extraReducers(builder) {
    // **************** CREATION *****************
    builder.addCase(createEstimate.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(createEstimate.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(createEstimate.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** PAGINED ESTIMATES LIST *****************
    builder.addCase(getPaginedEstimates.pending, (state) => {
      state.data.responsePaginedEstimates.estimates = [];
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getPaginedEstimates.fulfilled, (state, action) => {
      state.data.responsePaginedEstimates = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getPaginedEstimates.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** PAGINED ESTIMATES LIST *****************
    builder.addCase(getFilteredPaginedEstimates.pending, (state) => {
      state.data.responsePaginedEstimates.estimates = [];
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(getFilteredPaginedEstimates.fulfilled, (state, action) => {
      state.data.responsePaginedEstimates = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(getFilteredPaginedEstimates.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** PAGINED DEMANDS LIST *****************
    builder.addCase(getPaginedDemands.pending, (state) => {
      state.data.responsePaginedEstimates.estimates = [];
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getPaginedDemands.fulfilled, (state, action) => {
      state.data.responsePaginedEstimates = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getPaginedDemands.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** GET BY ID *****************
    builder.addCase(getDemandById.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getDemandById.fulfilled, (state, action) => {
      state.data.estimate = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getDemandById.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
    // **************** UPDATE CLARIFY *****************
    builder.addCase(updateClarify.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(updateClarify.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(updateClarify.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** UPDATE FILES *****************
    builder.addCase(updateFiles.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'POST';
    });
    builder.addCase(updateFiles.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'POST';
    });
    builder.addCase(updateFiles.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'POST';
    });
    // **************** ASSIGN REQUEST *****************
    builder.addCase(assignRequest.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'PUT';
    });
    builder.addCase(assignRequest.fulfilled, (state, action) => {
      state.data.responseMessage = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'PUT';
    });
    builder.addCase(assignRequest.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'PUT';
    });
    // **************** GET ALL ESTIMATE STATUS *****************
    builder.addCase(getAllEstimateStatus.pending, (state) => {
      state.isLoader = true;
      state.isSuccess = false;
      state.error = undefined;
      state.requestType = 'GET';
    });
    builder.addCase(getAllEstimateStatus.fulfilled, (state, action) => {
      state.data.listEstimateStatus = action.payload;
      state.isLoader = false;
      state.isSuccess = true;
      state.requestType = 'GET';
    });
    builder.addCase(getAllEstimateStatus.rejected, (state, action: PayloadAction<any>) => {
      state.isLoader = false;
      state.isSuccess = false;
      state.error = action.payload as AxiosError<ResponseMessage>;
      state.requestType = 'GET';
    });
  },
})

// Action creators are generated for each case reducer function
export const { estimatesSetResponseMessage } = estimatesSlice.actions

export default estimatesSlice