import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, FilledInput, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, SxProps, TextField, Theme, Typography } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StrengthMeter from "../../common/component/StrengthMeter";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteApp } from "../../common/model/RouteApp";
import { User } from "../../common/model/User";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { RootState } from "../../common/redux/store";
import { register } from "../../common/redux/usersActions";
import { stringService } from "../../common/service/stringService";
import imageback from '../../images/img-fond-fonce.svg';
import './RegistrationPage.scss';

const isResoFullHD: boolean = window.innerWidth > 1280;

interface RegistrationPageProps {

}

const RegistrationPage: FunctionComponent<RegistrationPageProps> = () => {

  const sxInputText: SxProps<Theme> = {
    backgroundColor: 'white',
    marginBottom: 2,
    '& label.Mui-focused': {
      color: '#6C6C6C',
    },
    '& .MuiFilledInput-underline:after': {
      borderColor: '#6C6C6C',
    }
  }

  const sxInputPassword: SxProps<Theme> = {
    backgroundColor: 'white',
    '& label.Mui-focused': {
      color: '#6C6C6C',
    },
    '& .MuiFilledInput-underline:after': {
      borderColor: '#6C6C6C',
    }
  }

  const dispatch = useAppDispatch();

  const usersSelector = useSelector((state: RootState) => state.users);
  const [error, seterror] = useState<ResponseMessage | undefined>(undefined);

  const [lastname, setlastname] = useState<string>('');
  const [firstname, setfirstname] = useState<string>('');

  const [isEmailValid, setisEmailValid] = useState<boolean>(false);
  const [email, setemail] = useState<string>('');
  const [username, setusername] = useState<string>('');
  const [company, setcompany] = useState<string>('');
  const [phoneNumber, setphoneNumber] = useState<string>('');

  function onChangeEmail(value: string) {
    setemail(value);
    setisEmailValid(stringService.isValidMail(value));
  }

  const [password1, setpassword1] = useState<string>('');
  const [password2, setpassword2] = useState<string>('');

  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  function handleClickShowPassword(numPassword: number) {
    if (numPassword === 1)
      setShowPassword1((show) => !show);
    else
      setShowPassword2((show) => !show);
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [isErrorPassword1, setErrorPassword1] = useState<string | null>(null);
  const [isErrorPassword2, setErrorPassword2] = useState<string | null>(null);

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, numPassword: number) => {
    let password = e.target.value;
    if (numPassword === 1) {
      setpassword1(e.target.value);
      setErrorPassword1(null);
    }
    else {
      setpassword2(e.target.value);
      setErrorPassword2(null);
    }

    let caps, small, num, specialSymbol;
    if (password.length < 8) {
      if (numPassword === 1)
        setErrorPassword1(
          "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial : @ $ ! % * ? & ."
        );
      return;
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/[@$!%*?&.]/g) || []).length;
      if (numPassword === 1) {
        if (caps < 1) {
          setErrorPassword1("Ajouter une lettre majuscule");
          return;
        } else if (small < 1) {
          setErrorPassword1("Ajouter une lettre minuscule");
          return;
        } else if (num < 1) {
          setErrorPassword1("Ajouter un nombre");
          return;
        } else if (specialSymbol < 1) {
          setErrorPassword1("Ajouter un caractère spécial : @ $ ! % * ? & .");
          return;
        }
      }
    }
  };
  const [isStrong1, initRobustPassword1] = useState<string | null>(null);
  const initPwdInput1 = async (childData?: string) => {
    initRobustPassword1(childData ? childData : '');
  };


  function subscribe() {
    if (password1 !== password2) {
      setErrorPassword2("Le mot de passe est différent")
    }
    else {
      setErrorPassword2(null)
      const userToRegister: User = {
        last_name: lastname,
        first_name: firstname,
        email: email,
        username: username,
        company: company.length > 0 ? company : null,
        phone: phoneNumber,
        password: {
          first: password1,
          second: password2
        }
      }
      seterror(undefined);
      dispatch(register(userToRegister));
    }
  }

  function isValidForm(): boolean {
    return isStrong1 === "fort" && isEmailValid && email.length > 0 && lastname.length > 0 &&
      firstname.length > 0 && username.length > 0 && phoneNumber.length > 0;
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (usersSelector.error) {
      seterror(usersSelector.error.response?.data);
    }
    if (usersSelector.isSuccess) {
      navigate(RouteApp.DASHBOARD);
    }
  }, [usersSelector]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${imageback})`,
        display: 'flex',
        height: '100%'
      }}
    >
      <Box margin={'auto'} width={1200}>
        <img
          src={require('../../images/logo-aeroflex-sans-fond.png')} alt="logo aeroflex"
          className="registration-logo"
        />
        <Box
          sx={{
            borderRadius: 2,
            background: 'rgb(255 255 255 / 10%)',
            paddingX: 15,
            paddingY: isResoFullHD ? 5 : 2
          }}
        >
          <Typography
            color={'white'}
            variant={'h4'}
            component={'h1'}
            marginBottom={isResoFullHD ? 5 : 4}
          >
            Inscription
          </Typography>
          <Grid container columnSpacing={10} rowSpacing={isResoFullHD ? 5 : 3}>
            <Grid item xs={6}>
              <TextField
                id="nom" label="Nom" variant="filled" required sx={sxInputText} fullWidth
                value={lastname} onChange={(e) => setlastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="prenom" label="Prénom" variant="filled" required sx={sxInputText} fullWidth
                value={firstname} onChange={(e) => setfirstname(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField id="email" type='email'
                label="Email" variant="filled" required
                sx={sxInputText} fullWidth
                error={!isEmailValid && email.length > 0}
                helperText={isEmailValid ? '' : email.length > 0 ? 'L’adresse email est invalide' : ''}
                value={email}
                onChange={(e) => onChangeEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="username" label="Identifiant" variant="filled" required sx={sxInputText} fullWidth
                error={error !== undefined}
                helperText={error ? error.message : ''}
                value={username} onChange={(e) => setusername(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="company" label="Société" variant="filled" sx={sxInputText} fullWidth
                value={company} onChange={(e) => setcompany(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPhoneNumber
                id="phoneNumber" label="N°Téléphone" required
                variant="filled" sx={sxInputText} fullWidth defaultCountry="fr"
                onChange={(event: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setphoneNumber(event.toString())}
                value={phoneNumber}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="filled" sx={sxInputPassword} fullWidth>
                <InputLabel required htmlFor="mdp1">Mot de passe</InputLabel>
                <FilledInput
                  id="mdp1"
                  sx={sxInputPassword}
                  type={showPassword1 ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword(1)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={isErrorPassword1 !== null}
                  value={password1}
                  onChange={(e) => onChangePassword(e, 1)}
                />
                {isErrorPassword1 !== null &&
                  <FormHelperText>
                    {isErrorPassword1}
                  </FormHelperText>
                }
              </FormControl>
              <StrengthMeter password={password1} actions={initPwdInput1} whereItUsed="registration" />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="filled" sx={sxInputPassword} fullWidth>
                <InputLabel required htmlFor="mdp2">Confirmation mot de passe</InputLabel>
                <FilledInput
                  id="mdp2"
                  sx={sxInputPassword}
                  type={showPassword2 ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword(2)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={isErrorPassword2 !== null}
                  value={password2}
                  onChange={(e) => onChangePassword(e, 2)}
                />
                {isErrorPassword2 !== null &&
                  <FormHelperText>
                    {isErrorPassword2}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
          </Grid>
          {

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Button variant="contained"
                // color="error"
                sx={{
                  marginX: "auto",
                  marginTop: isResoFullHD ? 12 : 4,
                  borderRadius: 5,
                  backgroundColor: '#E5332B',
                  width: "25%",
                  '&.Mui-disabled': {
                    backgroundColor: '#E5332B',
                    opacity: 0.65,
                    color: 'white'
                  },
                  '&.MuiButton-root:hover': {
                    backgroundColor: '#E5332B',
                    opacity: 0.8
                  }
                }}
                onClick={subscribe}
                disabled={!isValidForm()}
              >
                S'inscrire
              </Button>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
}

export default RegistrationPage;