import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Container, FormControl, Grid, IconButton, InputBase, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from '../../common/component/CustomSnackbar';
import PaginationComponent from '../../common/component/PaginationComponent';
import { ResponseMessage } from '../../common/model/ResponseMessage';
import { RouteApp } from '../../common/model/RouteApp';
import { Role, User, getRoleAsText } from '../../common/model/User';
import { useAppDispatch } from '../../common/redux/reduxHooks';
import { RootState } from '../../common/redux/store';
import { getFilteredPaginedUsers, getPaginedUsers } from '../../common/redux/usersActions';
import { setUser, usersSetResponseMessage } from '../../common/redux/usersSlice';
import imageback from '../../images/img-fond-fonce.svg';
import ItemUser from './ItemUser';
import "./ManageUsersPage.scss";

const isResoFullHD: boolean = window.innerWidth > 1280;

interface ManageUsersPageProps {

}

const ManageUsersPage: FunctionComponent<ManageUsersPageProps> = () => {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const usersSelector = useSelector((state: RootState) => state.users);

  const [currentPage, setcurrentPage] = useState<number>(1);

  const [snackbarMessage, setsnackbarMessage] = useState<ResponseMessage | undefined>(undefined);
  const [openSnackbar, setopenSnackbar] = useState<boolean>(false);

  const [filter, setfilter] = useState<"lastName" | "firstName" | "email" | "company" | "roles">('lastName');

  const [role, setrole] = useState<Role | string | undefined>(undefined);

  const [searchValue, setsearchValue] = useState<string>('');

  useEffect(() => {
    dispatch(getPaginedUsers(currentPage))
  }, []);

  useEffect(() => {
    if (usersSelector.data.responseMessage?.status === 200 && usersSelector.requestType !== 'DELETE') {
      setsnackbarMessage(usersSelector.data.responseMessage);
      setopenSnackbar(true);
      dispatch(usersSetResponseMessage(undefined));
    }
    else if (usersSelector.data.responseMessage?.status === 200 && usersSelector.requestType === 'DELETE') {
      if (usersSelector.data.responsePaginedUsers.users.length === 1) {
        dispatch(getPaginedUsers(currentPage - 1));
      }
      else {
        dispatch(getPaginedUsers(currentPage));
      }
      dispatch(usersSetResponseMessage(undefined));
    }
  }, [usersSelector]);

  function onChangePage(event: React.ChangeEvent<unknown>, page: number) {
    setcurrentPage(page);
    if (searchValue !== '' || role !== undefined) {
      dispatch(getFilteredPaginedUsers({
        numPage: page,
        property: filter,
        value: searchValue
      }))
    }
    else {
      dispatch(getPaginedUsers(page))
    }
  }

  const userConnected: User | undefined = useSelector((state: RootState) => state.userConnected.user);

  function getLbUsers(): string {
    if (userConnected?.roles && userConnected.roles[0] === Role.Commercial) {
      return 'clients';
    }
    return 'utilisateurs';
  }

  function getLbUser(): string {
    if (userConnected?.roles && userConnected.roles[0] === Role.Commercial) {
      return 'client';
    }
    return 'utilisateur';
  }

  function onClickAddUser() {
    dispatch(setUser(undefined));
    navigate(RouteApp.MANAGE_USERS + RouteApp.EDIT_USER);
  }

  function handleCloseSnackbar() {
    setopenSnackbar(false)
  }

  const handleChangeFilter = (event: SelectChangeEvent) => {
    setfilter(event.target.value as "lastName" | "firstName" | "email" | "company" | "roles");
  };

  const handleChangeRole = (event: SelectChangeEvent) => {
    setrole(event.target.value as Role);
  };

  function resetFilter() {
    setfilter('lastName');
    setrole(undefined);
    setsearchValue('');
    dispatch(getPaginedUsers(1));
    setcurrentPage(1);
  }

  return (
    <Box
      className="page-container"
      sx={{
        backgroundImage: `url(${imageback})`
      }}
    >
      <Container className='container'>
        <Typography className='title-page' variant='h4' component="h1">
          Gestion des {getLbUsers()}
        </Typography>

        <Paper className="manage-users-page-paper-container">
          <Box className="manage-users-page-actions-top-container">
            <Box className='filtres-container'>
              <Paper className='filtres-form'
                component="form"
                sx={{ p: isResoFullHD ? '2px 4px' : '0px', display: 'flex', alignItems: 'center', width: 400 }}
              >
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter"
                    value={filter}
                    onChange={handleChangeFilter}
                    label="Filtre"
                  >
                    <MenuItem value={'lastName'}>Nom</MenuItem>
                    <MenuItem value={'firstName'}>Prénom</MenuItem>
                    <MenuItem value={'email'}>Email</MenuItem>
                    <MenuItem value={'company'}>Société</MenuItem>
                    <MenuItem value={'roles'}>Rôle</MenuItem>
                  </Select>
                </FormControl>
                {
                  filter !== 'roles' ?
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Rechercher"
                      inputProps={{ 'aria-label': 'rechercher' }}
                      value={searchValue}
                      onChange={(e) => setsearchValue(e.target.value)}
                    />
                    :
                    <FormControl variant="standard" sx={{ ml: 1, flex: 1 }}>

                      <Select
                        labelId="select-role-label"
                        id="select-role"
                        value={role}
                        onChange={handleChangeRole}
                        label="Roles"
                      >
                        {
                          Object.values(Role).map((r: string, indice: number) => {
                            return <MenuItem key={indice} value={r}>{getRoleAsText(r)}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>
                }
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => {
                  setcurrentPage(1)
                  dispatch(getFilteredPaginedUsers({
                    numPage: 1,
                    property: filter,
                    value: filter !== 'roles' ? searchValue : role
                  }))
                }}>
                  <SearchIcon />
                </IconButton>
                <IconButton type="button" sx={{ p: '10px' }} aria-label="remove" onClick={resetFilter}>
                  <CloseIcon />
                </IconButton>
              </Paper>


            </Box>
            <Button
              variant="text"
              className="btn-ajouter-utilisateur"
              sx={{
                marginY: 'auto',
                textTransform: 'none'
              }}
              onClick={() => onClickAddUser()}
            >
              <AddCircleOutlineIcon
                className="icon-plus-ajouter-utilisateur"
              />Ajouter un utilisateur
            </Button>
          </Box>

          <Grid container className='header-list-users' sx={{
            backgroundColor: "#F3EDED"
          }}>
            <Grid item xs={3.6} display={"flex"}>
              <Typography margin={"auto 0 auto 100px"} className='header-list-users-title'>{getLbUser()}</Typography>
            </Grid>
            <Grid item xs={3.9} display={"flex"}>
              <Typography margin={"auto"} className='header-list-users-title'>Email</Typography>
            </Grid>
            <Grid item xs={3.9} display={"flex"}>
              <Typography margin={"auto"} className='header-list-users-title'>Rôle</Typography>
            </Grid>
            <Grid item xs={0.6}>
            </Grid>
          </Grid>

          {
            usersSelector.data.responsePaginedUsers.users.map((user: User, indice: number) => {
              return <ItemUser key={indice} user={user} />
            })
          }

        </Paper>
        <Box className='manage-users-page-pagination-container'>
          <PaginationComponent
            currentPage={currentPage}
            nbTotalPage={usersSelector.data.responsePaginedUsers.max_page}
            onChangePage={onChangePage}
          />
        </Box>

      </Container>
      <CustomSnackbar
        open={snackbarMessage !== undefined && openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        message={snackbarMessage ? snackbarMessage.message : ''}
        severity={snackbarMessage?.status === 200 ? "success" : "error"}
        position={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={usersSelector.data.responseMessage?.status === 200 ? 4000 : 6000}
      />
    </Box>
  );
}

export default ManageUsersPage;