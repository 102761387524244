import { Grid, Button, TextField, FormControl } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { emptyUser, User, UserToEdit } from "../../common/model/User";
import { RootState } from "../../common/redux/store";
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import MuiPhoneNumber from "material-ui-phone-number";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { getUser, updateUser } from "../../common/redux/usersActions";
import { setUserConnected } from "../../common/redux/userConnectedSlice";
import Popup from "../../common/component/Popup";
import { logout } from "../../login/redux/authAction";
import { useNavigate } from "react-router-dom";
import './MyAccountEditable.scss';
import { stringService } from "../../common/service/stringService";
import { RouteApp } from "../../common/model/RouteApp";


interface MyAccountEditableProps {
  setisEditMode: (value: React.SetStateAction<boolean>) => void;
}

const MyAccountEditable: FunctionComponent<MyAccountEditableProps> = (props: MyAccountEditableProps) => {

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const userSelector: User | undefined = useSelector((state: RootState) => state.users.data.user)

  const [currentUser, setcurrentUser] = useState<User>(emptyUser);

  const [isDisplayPopupDeconnexion, setisDisplayPopupDeconnexion] = useState<boolean>(false);

  useEffect(() => {
    if (userSelector)
      setcurrentUser(userSelector);
  }, [userSelector]);

  async function onClickSave() {
    const userToEdit: UserToEdit = {
      id: currentUser.id,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      username: currentUser.username,
      company: currentUser.company instanceof String ? 1 : currentUser.company ? currentUser.company.id : 1,
      phone: currentUser.phone,
      roles: currentUser.roles!
    }
    await dispatch(updateUser(userToEdit))

    if (userToEdit.username !== userSelector?.username) {
      setisDisplayPopupDeconnexion(true);
    }
    else {
      await dispatch(getUser(currentUser.id!));
      dispatch(setUserConnected(userSelector));
    }
  }

  function onLogOut() {
    dispatch(logout(true));
    navigate(RouteApp.LOGIN);
    setisDisplayPopupDeconnexion(false);
  }

  return (
    <>
      <Grid container spacing={2} sx={{ width: '700px', margin: 'auto' }}>
        <Grid item xs={6} className="my-account-view-label">Nom</Grid>
        <Grid item xs={6} className="my-account-view-label">Prénom</Grid>

        <Grid item xs={6}>
          <TextField
            id="last-name"
            value={currentUser?.last_name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const user: User = {
                ...currentUser,
                last_name: event.target.value
              };
              setcurrentUser(user);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="first-name"
            value={currentUser?.first_name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const user: User = {
                ...currentUser,
                first_name: event.target.value
              };
              setcurrentUser(user);
            }}
          />
        </Grid>

        <Grid item xs={6} className="my-account-view-label">Email</Grid>
        <Grid item xs={6} className="my-account-view-label">Identifiant</Grid>

        <Grid item xs={6}>
          <TextField
            id="email"
            type="email"
            value={currentUser?.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const user: User = {
                ...currentUser,
                email: event.target.value
              };
              setcurrentUser(user);
            }}
            error={!stringService.isValidMail(currentUser.email) && currentUser.email.length > 0}
            helperText={stringService.isValidMail(currentUser.email) ? '' : currentUser.email.length > 0 ? 'Adresse email est invalide' : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="username"
            disabled
            value={currentUser?.username}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const user: User = {
                ...currentUser,
                username: event.target.value
              };
              setcurrentUser(user);
            }}
          />
        </Grid>

        <Grid item xs={6} className="my-account-view-label">Téléphone</Grid>
        <Grid item xs={6} className="my-account-view-label">Société</Grid>

        <Grid item xs={6} className="my-account-view-data">
          <MuiPhoneNumber
            id="phoneNumber" required
            variant="outlined" defaultCountry="fr"
            onChange={(event: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const user: User = {
                ...currentUser,
                phone: event.toString()
              };
              setcurrentUser(user);
            }}
            value={currentUser.phone}
          />
        </Grid>
        <Grid item xs={6} className="my-account-view-data">
          <FormControl sx={{ width: '100%' }}>
            <TextField
              id="company"
              disabled
              value={userSelector?.company instanceof String ? userSelector?.company.toString() : userSelector?.company?.name}
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} className={"my-account-view-btns-container"}>
          <Button
            onClick={() => props.setisEditMode(false)}
            startIcon={<ClearIcon />}
            className="my-account-editable-cancel-btn"
          >
            Annuler
          </Button>
        </Grid>
        <Grid item xs={6} className={"my-account-view-btns-container"}>
          <Button
            onClick={() => onClickSave()}
            startIcon={<SaveIcon />}
            className="my-account-editable-save-btn"
          >
            Enregistrer
          </Button>
        </Grid>

      </Grid>

      <Popup
        open={isDisplayPopupDeconnexion}
        title={'information'}
        message={'Vous devez vous reconnecter suite à la modification de votre identifiant de connexion'}
        onClose={() => setisDisplayPopupDeconnexion(false)}
        onValidation={onLogOut}
        titleButtonValidation={"Valider"}
      />
    </>
  );
}

export default MyAccountEditable;