import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { User } from "../../common/model/User";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { RootState } from "../../common/redux/store";
import { getUser } from "../../common/redux/usersActions";
import "./MyAccountView.scss";
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
import { RouteApp } from "../../common/model/RouteApp";

const isResoFullHD: boolean = window.innerWidth > 1280;

interface MyAccountViewProps {
  setisEditMode: (value: React.SetStateAction<boolean>) => void;
}

const MyAccountView: FunctionComponent<MyAccountViewProps> = (props: MyAccountViewProps) => {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const userConnected: User | undefined = useSelector((state: RootState) => state.userConnected.user);

  useEffect(() => {
    if (userConnected?.id) {
      dispatch(getUser(userConnected.id));
    }
  }, [userConnected]);

  const userSelector: User | undefined = useSelector((state: RootState) => state.users.data.user)

  return (
    <>
      <Grid container spacing={2} marginX={isResoFullHD ? '10%' : '4%'}>
        <Grid item xs={6} className="my-account-view-label">Email</Grid>
        <Grid item xs={6} className="my-account-view-label">Identifiant</Grid>

        <Grid item xs={6} className="my-account-view-data">{userSelector?.email}</Grid>
        <Grid item xs={6} className="my-account-view-data">{userSelector?.username}</Grid>

        <Grid item xs={6} className="my-account-view-label">Téléphone</Grid>
        <Grid item xs={6} className="my-account-view-label">Société</Grid>

        <Grid item xs={6} className="my-account-view-data">{userSelector?.phone}</Grid>
        <Grid item xs={6} className="my-account-view-data">
          {userSelector?.company instanceof String ? userSelector?.company.toString() : userSelector?.company?.name}
        </Grid>

        <Grid item xs={6} className={"my-account-view-btns-container"}>
          <Button
            onClick={() => navigate(RouteApp.CHANGE_PASSWORD)}
            startIcon={<LockIcon />}
            className="my-account-view-change-password"
          >
            changer le mot de passe
          </Button>
        </Grid>
        <Grid item xs={6} className={"my-account-view-btns-container"}>
          <Button
            onClick={() => props.setisEditMode(true)}
            startIcon={<EditIcon />}
            className="my-account-view-modify"
          >
            modifier
          </Button>
        </Grid>

      </Grid>
    </>
  );
}

export default MyAccountView;